import React, { useEffect, useState } from 'react';
import { IoArrowBackSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import './Notification.css';
import axios from 'axios';
// import { API } from '../../services/API';
import { toast } from 'react-toastify';
import announcement from './../../images/announcement.png';
import payment from './../../images/payment.png';
import winning from './../../images/winning.jpg';
import API_ENDPOINTS from '../../config/api';
import Header from '../../components/header/Header';


const Notification = () => {
    const navigate = useNavigate();
    const [notification, setNotification] = useState([]);

    // useEffect(() => {
    //     fetchNotification();
    // }, []);

    const GameType = [
        { id: 1, Referrals: "20.10.24", Deposit: "5%", message: "We have exciting offer for you We have exciting offer for you" },
        { id: 2, Referrals: "20.10.24", Deposit: "5%", message: "We have exciting offer for you" },
        { id: 3, Referrals: "20.10.24", Deposit: "5%", message: "We have exciting offer for you" },
        { id: 4, Referrals: "20.10.24", Deposit: "5%", message: "We have exciting offer for you" },
        { id: 5, Referrals: "20.10.24", Deposit: "5%", message: "We have exciting offer for you" },
        { id: 6, Referrals: "20.10.24", Deposit: "5%", message: "We have exciting offer for you" },
    ];

    // const fetchNotification = async () => {
    //     const getTokenDetails = localStorage.getItem("storeData");

    //     if (!getTokenDetails) {
    //         toast.error("No token found, please log in again.");
    //         navigate("/login");
    //         return;
    //     }

    //     const token = JSON.parse(getTokenDetails).data.token;

    //     const payload = JSON.stringify({
    //         token: token
    //     })

    //     console.log('token', payload)

    //     try {
    //         const response = await axios.post(API.Notification, payload, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //         });

    //         const ResponseData = response.data;

    //         console.log("ResponseData in notify", ResponseData);

    //         setNotification(ResponseData.data)

    //         // Process the response data here
    //         // setWallet(ResponseData.data.wallet.main_wallet);
    //     } catch (error) {
    //         if (error.response) {
    //             console.log("Server responded with status code:", error.response.status);
    //             console.log("Response data:", error.response.data.message);
    //             toast.error(error.response.data.message);
    //         } else if (error.request) {
    //             console.log("No response received:", error.request);
    //         } else {
    //             console.log("Error creating request:", error.message);
    //         }
    //     }
    // };

    // const handleLogin = async (e) => {
    //     navigate('/tab');

    //     e.preventDefault();
    //     try {
    //         // const loginData = activeTab === "phone" ? { phone, password } : { email, password };
    //         const response = await apiPost(API_ENDPOINTS.AUTH.LOGIN, loginData);
    //         localStorage.setItem('token', response.token);
    //         navigate('/');
    //     } catch (error) {
    //         console.error('Login failed:', error);
    //         // alert('Login failed. Please try again.');
    //     }
    // };

    // const handlePress = async (id) => {
    //     // const getTokenDetails = localStorage.getItem("storeData");

    //     // if (!getTokenDetails) {
    //     //     toast.error("No token found, please log in again.");
    //     //     navigate("/login");
    //     //     return;
    //     // }

    //     // const token = JSON.parse(getTokenDetails).data.token;

    //     const payload = JSON.stringify({
    //         id: id
    //     })

    //     // console.log('token', payload)

    //     try {
    //         const response = await axios.post(API.ReadNotify, payload, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //         });

    //         const ResponseData = response.data;

    //         console.log("ResponseData in notify", ResponseData);

    //         setNotification(ResponseData.data)

    //         // Process the response data here
    //         // setWallet(ResponseData.data.wallet.main_wallet);
    //     } catch (error) {
    //         if (error.response) {
    //             console.log("Server responded with status code:", error.response.status);
    //             console.log("Response data:", error.response.data.message);
    //             toast.error(error.response.data.message);
    //         } else if (error.request) {
    //             console.log("No response received:", error.request);
    //         } else {
    //             console.log("Error creating request:", error.message);
    //         }
    //     }
    // };

    const renderItem = (item) => {

        let icon;

        switch (item.type) {
            case 'Payment':
                icon = payment;
                break;
            case 'Result':
                icon = winning;
                break;
            default:
                icon = announcement;
                break;
        }

        const formattedDate = new Date(item.createdAt).toLocaleDateString('en-GB');

        return (
            <div className="NRowStyle" key={item.id}>
                <label className="Ntext2">{formattedDate}</label>
                <div className="NRowStyle1">
                    <img src={icon} alt="icon" className='Nicons' />
                    <label className="Ntext1">{item.message}</label>
                </div>
            </div>
        );
    };

    return (
        <div className="NContainer">
            {/* <div className="DepositHead">
                <button className="header" onClick={() => navigate(-1)}>
                    <IoArrowBackSharp />
                </button>
                <h1 className="text1">Notification</h1>
            </div> */}
            <Header title={'Notification'}/>

            {notification.length === 0 ? (
                <p className="no-notification">No Notification available</p>
            ) : (
                notification.map(renderItem)
            )}

            {/* {notification.map(renderItem)} */}
        </div>
    );
};

export default Notification;
