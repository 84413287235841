// NotificationHeader.js
import React from 'react';
import { IoArrowBackSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import './Header.css'; 

const Header = ({title}) => {
  const navigate = useNavigate();

  return (
    <div className="DepositHead">
      <button className="header" onClick={() => navigate(-1)}>
        <IoArrowBackSharp />
      </button>
      <h1 className="text1">{title}</h1>
    </div>
  );
};

export default Header;
