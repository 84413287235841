import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SplashScreen from './components/splashscreen/SplashScreen';
import routes from './config/routes';
import APP_CONSTANTS from './config/constants';
import './assets/styles.css';
import './Theme.css';

function App() {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            const token = localStorage.getItem('token');
            if (token) {
                setIsAuthenticated(true);
            }
            setIsLoading(false);
        }, APP_CONSTANTS.SPLASH_SCREEN_DELAY);

        return () => clearTimeout(timer);
    }, [isAuthenticated]);

    return (
        <Router>
            <div className="app-wrapper">
                <div className="app-content">
                    {isLoading ? (
                        <SplashScreen />
                    ) : (
                        // <AppRoutes/>
                        <Routes>
                            {routes.map((route, index) => (
                                <Route key={index} {...route} />
                            ))}
                        </Routes>
                    )}
                </div>
            </div>
        </Router>
    );
}

export default App;
