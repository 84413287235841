import React from 'react';
import './OtherCategoryMenu.css';
import {useNavigate} from "react-router-dom";

const OtherCategoryMenu = ({ otherGames, showTitle, listCount }) => {
    const navigate = useNavigate();

    const onClickMenu = (index)=>{
        console.log("Selected game: ", index);
        console.log("Selected Launch URL", otherGames[index].launch_url);
        navigate(otherGames[index].launch_url);
    }

    return (
        <div className={`home-menu-grid-${listCount}`}>
            {otherGames.map((category, index) => (
                <div className={`game-card-${listCount}`} onClick={() => {
                    onClickMenu(index)
                }} key={index}>
                    <img src={category.image} alt={category.name} className={category.name.toLowerCase()}/>
                    { showTitle() && (
                        <span className="game-card-title">{category.name}</span>
                    )}
                </div>
            ))}
        </div>
    );
};

export default OtherCategoryMenu;
