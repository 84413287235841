import React from 'react';
import { Navigate } from 'react-router-dom';
import Login from './../pages/login/Login';
import Register from './../pages/register/Register';
import ForgotPassword from './../pages/forgotPassword/ForgotPassword';
import PrivateRoute from './../components/privateRoute/PrivateRoute';
import Home from '../pages/home/Home';
import Activity from '../pages/activity/Activity';
import Promotion from '../pages/promotion/Promotion';
import Wallet from '../pages/wallet/Wallet';
import Account from '../pages/account/Account';
import Notification from '../pages/notification/Notification';
import Deposit from '../pages/Deposit/Deposit';
import Wingo from "../pages/lottery/wingo/wingo";
import GameSlots from "../pages/games/game-slots";
import GameSpribe from "../pages/games/game-spribe";


// Unsecured routes
const unsecuredRoutes = [
    { path: "/login", element: <Login /> },
    { path: "/register", element: <Register /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/", element: <Home />}
];

// Secured routes
const securedRoutes = [
    { path: "/activity", element: <PrivateRoute><Activity /></PrivateRoute> },
    { path: "/promotion", element: <PrivateRoute><Promotion /></PrivateRoute> },
    { path: "/wallet", element: <PrivateRoute><Wallet /></PrivateRoute> },
    { path: "/account", element: <PrivateRoute><Account /></PrivateRoute> },
    { path: "/notification", element: <PrivateRoute><Notification /></PrivateRoute> },
    { path: "/deposit", element: <PrivateRoute><Deposit /></PrivateRoute> },
    { path: "/lottery/wingo", element: <PrivateRoute><Wingo /></PrivateRoute> },
    { path: "/game/slots/:gameid", element: <PrivateRoute><GameSlots /></PrivateRoute> },
    { path: "/game/spribe/:gameid", element: <PrivateRoute><GameSpribe /></PrivateRoute> }
];

const routes = [
    ...unsecuredRoutes,
    ...securedRoutes,
    { path: "*", element: <Navigate to="/login" /> }
];

export default routes;