import React from "react";
import { useNavigate } from "react-router-dom";
import "./Activity.css";
import BottomNavigator from "../../components/bottomNavigator/BottomNavigator";
// import { text } from "@fortawesome/fontawesome-svg-core";

const Activity = (props) => {
  let navigate = useNavigate();

  const banners = [
    {
      imageUrl:
        "https://ossimg.9987cw.cc/TC/banner/Banner_20240322141441yrry.jpg",
      text: "FIRST DEPOSIT BONUS",
      onClick: () => navigate("/DepositBonus"),
    },
    {
      imageUrl:
        "https://ossimg.9987cw.cc/TC/banner/Banner_20240327161916hk21.jpg ",
      text: "TC CREATIVE VIDEO CONTEST",
      onClick: () => navigate("/ActivityDetails", { state: { imageUrl: "https://ossimg.9987cw.cc/TC/banner/Banner_20240327161916hk21.jpg",text:"TC CREATIVE VIDEO CONTEST" } }),
    },
    {
      imageUrl:
        "https://ossimg.9987cw.cc/TC/banner/Banner_20240321192925o4dy.jpg ",
      text: "WINSTREAK 2X PRIZE HAPPY HOUR",
      onClick: () => navigate("/ActivityDetails", { state: { imageUrl: "https://ossimg.9987cw.cc/TC/banner/Banner_20240321192925o4dy.jpg",text:"WINSTREAK 2X PRIZE HAPPY HOUR" } }),
    },
    {
      imageUrl:
        "https://ossimg.9987cw.cc/TC/banner/Banner_20240327161931king.jpg ",
      text: "DAILY BONUS UNTIL 1 CRORE",
      onClick: () => navigate("/ActivityDetails", { state: { imageUrl: "https://ossimg.9987cw.cc/TC/banner/Banner_20240327161931king.jpg",text:"DAILY BONUS UNTIL 1 CRORE" } }),
    },
    {
      imageUrl:
        "https://ossimg.9987cw.cc/TC/banner/Banner_20240321234928w2rw.jpg ",
      text: "BEST PILOT TC AIRLINES",
      onClick: () => navigate("/ActivityDetails", { state: { imageUrl: "https://ossimg.9987cw.cc/TC/banner/Banner_20240321234928w2rw.jpg",text:"BEST PILOT TC AIRLINES" } }),
    },
    {
      imageUrl:
        "https://ossimg.9987cw.cc/TC/banner/Banner_2024032212464358mq.jpg ",
      text: "MONTHLY VIP BONUS",
      onClick: () => navigate("/ActivityDetails", { state: { imageUrl: "https://ossimg.9987cw.cc/TC/banner/Banner_2024032212464358mq.jpg",text:"MONTHLY VIP BONUS" } }),
    },
    {
      imageUrl:
        "https://ossimg.9987cw.cc/TC/banner/Banner_20240322134401ujk4.jpg ",
      text: "TC SUPPORT FUNDS",
      onClick: () => navigate("/ActivityDetails", { state: { imageUrl: "https://ossimg.9987cw.cc/TC/banner/Banner_20240322134401ujk4.jpg" ,text:"TC SUPPORT FUNDS"} }),
    },
  ];

  return (
    <div className="ActivityContainer">
      <div className="activityName">{}</div>
      <div className="ActivityHeader">
        <div className="aheadtext">Activity</div>

        <div className="aheadtext1">
          Please remember to follow the event page
        </div>

        <div className="aheadtext1">
          We will launch user feedback activities from time to time
        </div>
      </div>

      <div className="amiddle">
        <div className="aimgs" onClick={() => navigate("/Gift")}>
          <img
            src={"https://9987up.club/assets/png/signInBanner-33f86d3f.png"}
            alt="Image"
            className="innerimg1"
            // style={{ width: '50%', height: '100%',backgroundColor:'red' }}
          />
          <div className="innerimg1text1">Gifts</div>
          <div className="innerimg1text2">
            Enter the redemption code to receive gift rewards
          </div>
        </div>

        <div className="aimgs"> 
          <img
            src={"https://9987up.club/assets/png/giftRedeem-45917887.png"}
            alt="Image"
            className="innerimg2"

            // style={{ width: '50%', height: '100%' ,backgroundColor:'red'}}
          />
          <div className="innerimg1text1">Attendance bonus</div>
          <div className="innerimg1text2">
            The more consecutive days you sign in.
          </div>
        </div>
      </div>

      <div className="abottom">
        {banners.map((banner, index) => (
          <div key={index} className="abanner" onClick={banner.onClick}>
            <img src={banner.imageUrl} alt="Banner" className="abannerImg" />
            <div className="abannerImgTxt">{banner.text}</div>
          </div>
        ))}
      </div>
      <BottomNavigator/>
    </div>
  );
};

export default Activity;
