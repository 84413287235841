import React, {useEffect, useState} from 'react';
import {IoTimerSharp, IoWallet} from "react-icons/io5";
import './../../../Theme.css';
import './wingo.css';
import {AiOutlineInfoCircle} from "react-icons/ai";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import Image from "../../../images/logo_2.png";

function WingoGame() {
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

    //Game Options
    const [lastResults, setLastResults] = useState([0, 1, 2, 3, 4]);
    const [periodNo, setPeriodNo] = useState(2024578955126);
    const [timeRemainingInSeconds, setTimeRemainingInSeconds] = useState(30);
    const [tabIndex, setTabIndex] = useState(0);
    const [clicked, setClicked] = useState(false);
    const [isBlockClick, setBlocked] = useState(false);

    //Bet Options
    const [multiplier, setMultiplier] = useState(1);
    const [gameId, setgameId] = useState(0);

    const timeRemaining = formatTime(timeRemainingInSeconds);

    //History Game
    const [historyData, setHistoryData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const recordsPerPage = 10;

    //History Game
    const [myData, setMyDataData] = useState([]);
    const [myCurrentPage, setMyCurrentPage] = useState(1);
    const [myTotalRecords, setMyTotalRecords] = useState(0);

    const messages = [
        "Participate in today's color prediction and win exciting rewards! Ensure submit your predictions before the timer runs out.",
        "Reminder: Predictions are locked after the game starts. Double-check your selection and stay tuned for the results!",
        "Your winnings will be credited instantly after the game ends. Be sure to track your prediction history for accuracy!"
    ];
    const gameNames = [
        "Win Go 30s",
        "Win Go 1Min",
        "Win Go 3Min",
        "Win Go 5Min",
    ]

    //for alert message display
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }, 3000);
        return () => clearInterval(interval);
    }, [messages.length]);

    //for game timer
    useEffect(() => {
        let countDownDate = getNextCountdownTime(gameId);

        const updateCountdown = () => {
            const now = new Date().getTime();
            const distance = countDownDate - now;

            // Calculate remaining time in seconds
            const timeLeftInSeconds = Math.floor(distance / 1000);
            setTimeRemainingInSeconds(timeLeftInSeconds >= 0 ? timeLeftInSeconds : 0);

            //If the countdown is at 5 seconds
            if (timeLeftInSeconds <= 5) {
                setBlocked(true);
            }else{
                setBlocked(false);
            }

            // If the countdown is over, reset the timer
            if (timeLeftInSeconds < 1) {
                setBlocked(false);
                setTimeRemainingInSeconds((getGameInterval(gameId)/ 1000));
                countDownDate = getNextCountdownTime(gameId);
            }

        };

        updateCountdown();
        const interval = setInterval(updateCountdown, 1000);
        return () => clearInterval(interval);

    }, [gameId]);

    //For game history pagination
    useEffect(() => {
        fetchHistoryData(currentPage).then(()=>{console.log("Data Fetched")});
    }, [currentPage]);

    const totalPages = Math.ceil(totalRecords / recordsPerPage);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        fetchHistoryData(currentPage).then(()=>{console.log("Data Fetched")});
    };

    const fetchHistoryData = async (page) => {
        // const response = await fetch(`/api/history?page=${page}&limit=${recordsPerPage}`);
        // const result = await response.json();
        const result = testHistoryData();
        setHistoryData(result.data);
        setTotalRecords(result.totalRecords);
    };

    //For bet history pagination
    useEffect(() => {
        fetchMyHistoryData(myCurrentPage).then(()=>{console.log("Bet Data Fetched")});
    }, [myCurrentPage]);

    const myTotalPages = Math.ceil(myTotalRecords / recordsPerPage);

    const handleMyPageChange = (newPage) => {
        setMyCurrentPage(newPage);
        fetchMyHistoryData(myCurrentPage).then(()=>{console.log("Bet Data Fetched")});
    };

    const fetchMyHistoryData = async (page) => {
        const result = testMyHistoryData();
        setMyDataData(result.data);
        setMyTotalRecords(result.totalRecords);
    };

    return (
        <div className="wingo-container">

            {/*Header*/}
            <div className="HomeHeader">
                <div className="HeaderLeft">
                    <img src={Image} className="HeaderImage" />
                </div>
            </div>

            {/* Header */}
            <header className="header">

                <div className="header-content">

                    {/* Wallet Section */}
                    <div className="wallet">
                        <span>₹0.00</span>
                        <p><IoWallet className='wallet-icon'/>Wallet balance</p>
                        <div className="wallet-buttons">
                            <button className="withdraw-btn">Withdraw</button>
                            <button className="deposit-btn">Deposit</button>
                        </div>
                    </div>

                    {/* Alert Information */}
                    <div className="notify-box">
                        {messages.map((message, index) => (
                            <p
                                key={index}
                                style={{
                                    transform: `translateY(-${currentMessageIndex * 10}%)`,
                                    display: currentMessageIndex === index ? 'block' : 'none'
                                }}
                            >
                                {message}
                            </p>
                        ))}
                    </div>
                </div>

            </header>

            <div className="body-content">

                {/* Time Selection */}
                <div className="time-selection">
                    <button className={`time-option ${gameId === 0 ? 'active' : ''}`} onClick={() => setgameId(0)}>
                        <IoTimerSharp className='timerIcons'/>
                        <p>Win Go<br/>30s</p>
                    </button>
                    <button className={`time-option ${gameId === 1 ? 'active' : ''}`} onClick={() => setgameId(1)}>
                        <IoTimerSharp className='timerIcons'/>
                        <p>Win Go<br/>1Min</p>
                    </button>
                    <button className={`time-option ${gameId === 2 ? 'active' : ''}`} onClick={() => setgameId(2)}>
                        <IoTimerSharp className='timerIcons'/>
                        <p>Win Go<br/>3Min</p>
                    </button>
                    <button className={`time-option ${gameId === 3 ? 'active' : ''}`} onClick={() => setgameId(3)}>
                        <IoTimerSharp className='timerIcons'/>
                        <p>Win Go<br/>5Min</p>
                    </button>
                </div>

                {/* Timer */}
                <div className="timer-section">
                    <div className="info-box">
                        <button className='how-to-play'><AiOutlineInfoCircle className='wallet-icon' /> How to play</button>
                        <p>{ gameNames[gameId] }</p>
                        <div className="last-results">
                            {lastResults.map((num, index) => (
                                <span key={index} className={`number-btn-result number-item-${num}`}>{num}</span>
                            ))}
                        </div>
                    </div>
                    <div className="timer">
                        <span>Time remaining</span>
                        <span className="countdown">
                            {Array.from(timeRemaining).map((char, index) => (
                                <div key={index}>{char}</div>
                            ))}
                        </span>
                        <span className='period-no'>{ periodNo }</span>
                    </div>
                </div>


                {/* Bet Area */}
                <div className="bet-area">

                    {/* Overlay to Block clicks*/}
                    <div className={`overlay-block ${isBlockClick ? 'd-block' : ''}`}>
                        <span className="countdown-big">
                            {Array.from(timeRemaining).slice(3, 5).map((char, index) => (
                                <div key={index}>{char}</div>
                            ))}
                        </span>
                    </div>

                    {/* Color and Number Selection */}
                    <div className="color-selection">
                        <button className="color-btn green-selector">Green</button>
                        <button className="color-btn violet-selector">Violet</button>
                        <button className="color-btn red-selector">Red</button>
                    </div>
                    <div className="number-selection-container">
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
                            <div key={num} className={`number-item`}>
                                <button className={`number-btn number-item-${num}`}>{num}</button>
                            </div>
                        ))}
                    </div>

                    {/* Random and Multipliers */}
                    <div className="multiplier-section">
                        <button className="random-btn">Random</button>
                        <div className="multipliers">
                            {[1, 5, 10, 20, 50, 100].map((mult) => (
                                <button
                                    key={mult}
                                    className={`multiplier-btn ${multiplier === mult ? 'active' : ''}`}
                                    onClick={() => setMultiplier(mult)}
                                >
                                    X{mult}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Big and Small Buttons */}
                    <div className="size-buttons">
                        <button className="big-btn">Big</button>
                        <button className="small-btn">Small</button>
                    </div>

                </div>

                {/* Tabs */}
                <div className="tabs">
                    <button onClick={ ()=>setTabIndex(0) } className={`tab ${tabIndex === 0 ? 'active' : ''}`}>Game history</button>
                    {/*<button onClick={ ()=>setTabIndex(1) } className={`tab ${tabIndex === 1 ? 'active' : ''}`}>Chart</button>*/}
                    <button onClick={ ()=>setTabIndex(2) } className={`tab ${tabIndex === 2 ? 'active' : ''}`}>My history</button>
                </div>

                {/* Game History Table */}
                {tabIndex === 0 && (
                    <div className="game-history">
                        <div className="history-card">
                            <div className="history-header">
                                <span>Period</span>
                                <span>Number</span>
                                <span>Big Small</span>
                                <span>Color</span>
                            </div>

                            {historyData.map((item, index) => (
                                <div key={index} className="history-row">
                                    <span>{item.period}</span>
                                    <span dangerouslySetInnerHTML={formatNumberColored(item.number)}></span>
                                    <span>{item.bigSmall}</span>
                                    <span>{item.color}</span>
                                </div>
                            ))}

                            <div className="pagination">
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}>
                                    <IoIosArrowBack />
                                </button>

                                <span>{currentPage}/{totalPages}</span>

                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}>
                                    <IoIosArrowForward />
                                </button>
                            </div>

                        </div>
                    </div>
                )}

                {/* My History */}
                {tabIndex === 2 && (
                    <div className={`my-history ${ myData.length === 0 ? 'no-data-img' : ''}`} >
                        <div className="history-card">
                            {/*<div className="history-header">*/}
                            {/*    <span>Period</span>*/}
                            {/*    <span>Number</span>*/}
                            {/*    <span>Big Small</span>*/}
                            {/*    <span>Color</span>*/}
                            {/*</div>*/}

                            {historyData.map((item, index) => (
                                <div key={index} className="history-row">
                                    <span>{item.period}</span>
                                    <span dangerouslySetInnerHTML={formatNumberColored(item.number)}></span>
                                    <span>{item.bigSmall}</span>
                                    <span>{item.color}</span>
                                </div>
                            ))}

                            <div className="pagination">
                                <button
                                    onClick={() => handleMyPageChange(myCurrentPage - 1)}
                                    disabled={myCurrentPage === 1}>
                                    <IoIosArrowBack />
                                </button>

                                <span>{myCurrentPage}/{myTotalPages}</span>

                                <button
                                    onClick={() => handleMyPageChange(myCurrentPage + 1)}
                                    disabled={myCurrentPage === myTotalPages}>
                                    <IoIosArrowForward />
                                </button>
                            </div>

                        </div>

                    </div>
                )}


            </div>



        </div>
    );
}

function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const secs = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${secs}`;
}

function getGameInterval(gameId) {
    const intervals = [30 * 1000, 60 * 1000, 180 * 1000, 300 * 1000, 1200 * 1000];
    return intervals[gameId];
}

function getNextCountdownTime(gameId) {
    const interval = getGameInterval(gameId);
    const now = new Date().getTime();

    return Math.ceil(now / interval) * interval;
}

function formatNumberColored(str){
    return { __html: `<span class='numbers-colored-${str}'>${str}</span>` };
}

function testHistoryData(){
    return {
        "data": [
            {
                "period": "20241002301984",
                "number": "5",
                "bigSmall": "Big",
                "color": "Green"
            },
            {
                "period": "20241002301985",
                "number": "2",
                "bigSmall": "Small",
                "color": "Red"
            },
            {
                "period": "20241002301986",
                "number": "9",
                "bigSmall": "Big",
                "color": "Blue"
            },
            {
                "period": "20241002301987",
                "number": "4",
                "bigSmall": "Small",
                "color": "Yellow"
            },
            {
                "period": "20241002301988",
                "number": "8",
                "bigSmall": "Big",
                "color": "Purple"
            },
            {
                "period": "20241002301989",
                "number": "1",
                "bigSmall": "Small",
                "color": "Green"
            },
            {
                "period": "20241002301990",
                "number": "7",
                "bigSmall": "Big",
                "color": "Red"
            },
            {
                "period": "20241002301991",
                "number": "3",
                "bigSmall": "Small",
                "color": "Blue"
            },
            {
                "period": "20241002301992",
                "number": "6",
                "bigSmall": "Big",
                "color": "Yellow"
            },
            {
                "period": "20241002301993",
                "number": "0",
                "bigSmall": "Small",
                "color": "Purple"
            }
        ],
        "totalRecords": 50
    }
}

function testMyHistoryData(){
    return {
        "data": [
            {
                "period": "20241002301984",
                "date": "2024-10-01 21:42:15",
                "number": "5",
                "result": "Fail",
                "amount": "-100"
            },
            {
                "period": "20241002301985",
                "date": "2024-10-01 21:41:15",
                "number": "8",
                "result": "Success",
                "amount": "+200"
            },
            {
                "period": "20241002301986",
                "date": "2024-10-01 21:40:15",
                "number": "3",
                "result": "Fail",
                "amount": "-100"
            },
            {
                "period": "20241002301987",
                "date": "2024-10-01 21:39:15",
                "number": "6",
                "result": "Success",
                "amount": "+150"
            },
            {
                "period": "20241002301988",
                "date": "2024-10-01 21:38:15",
                "number": "9",
                "result": "Success",
                "amount": "+250"
            },
            {
                "period": "20241002301989",
                "date": "2024-10-01 21:37:15",
                "number": "1",
                "result": "Fail",
                "amount": "-100"
            },
            {
                "period": "20241002301990",
                "date": "2024-10-01 21:36:15",
                "number": "7",
                "result": "Success",
                "amount": "+300"
            },
            {
                "period": "20241002301991",
                "date": "2024-10-01 21:35:15",
                "number": "4",
                "result": "Fail",
                "amount": "-100"
            },
            {
                "period": "20241002301992",
                "date": "2024-10-01 21:34:15",
                "number": "2",
                "result": "Success",
                "amount": "+100"
            },
            {
                "period": "20241002301993",
                "date": "2024-10-01 21:33:15",
                "number": "0",
                "result": "Fail",
                "amount": "-100"
            }
        ],
        "totalRecords": 37
    }

}

function playAudio1() {
    console.log("Audio 1 played");
}

function playAudio2() {
    console.log("Audio 2 played");
}

export default WingoGame;
