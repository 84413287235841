export const saveLanguage=(index)=>{
    localStorage.setItem('my_lang', index);
}

export const myLanguage = () => {
    const lang = localStorage.getItem('my_lang');
    return lang !== null ? lang : 0;
};

export const getUser = () => {
    return JSON.parse(localStorage.getItem('user'));
};

export const saveUser =(user, token)=>{
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
}

export const logoutUser = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
};