import React, {useEffect, useState} from "react";
import Image from "../../images/logo_2.png";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import BottomNavigator from "../../components/bottomNavigator/BottomNavigator";
import HomeBanner from "../../components/banners/HomeBanner";
import NewsTicker from "../../components/newsTicker/newsTicker";
import GameHomeCategories from "../../components/gameCategories/GameHomeCategories";
import LotteryMenus from "../../components/lotteryMenus/LotteryMenus";
import OtherCategoryMenu from "../../components/otherCategoryMenu/OtherCategoryMenu";
import { IoColorFilterSharp, IoGameControllerSharp, IoFootballSharp  } from 'react-icons/io5';
import { FaDice, FaStar, FaTicketAlt } from 'react-icons/fa';
import { GiPokerHand } from "react-icons/gi";
import {apiGet} from "../../services/apiService";
import apiConfig from "../../config/api";

const gameCategories = [
    { name: 'Lottery', image: 'https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_20231215033613klhe.png', icon: FaTicketAlt },
    { name: 'Slots', image: 'https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_202312150336204mtb.png', icon: IoGameControllerSharp },
    { name: 'Sports', image: 'https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_20231215033607yi17.png', icon: IoFootballSharp },
    { name: 'Casino', image: 'https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_20231215033600k8os.png', icon: GiPokerHand  },
    { name: 'PVC', image: 'https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_20231215033554mpgb.png', icon: FaDice },
    { name: 'Live Casino', image: 'https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_20231215033528g3gt.png', icon: GiPokerHand },
    { name: 'Mini Games', image: 'https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_2023121503353389nc.png', icon: IoGameControllerSharp },
    { name: 'Popular', image: 'https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_202312150336366phx.png', icon: FaStar }
];

const lotteryGames = [
    { name: 'Win Go', image: 'https://ossimg.9987cw.cc/TC/lotterycategory/lotterycategory_202312150334238m3v.png', description: 'Guess Number \nGreen/Red/Violet to win', launch_url: '/lottery/wingo' },
    { name: 'K3 Lotre', image: 'https://ossimg.9987cw.cc/TC/lotterycategory/lotterycategory_20231215033442jmqv.png', description: 'Guess Number \nBig/Small/Odd/Even', launch_url: '/lottery/wingo'  },
    { name: '5D Lotre', image: 'https://ossimg.9987cw.cc/TC/lotterycategory/lotterycategory_20231215033448bjlv.png', description: 'Guess Number \nBig/Small/Odd/Even', launch_url: '/lottery/wingo'  },
    { name: 'Trx Win Go', image: 'https://ossimg.9987cw.cc/TC/lotterycategory/lotterycategory_20231215033454m1k3.png', description: 'Guess Number \nGreen/Red/Violet to win', launch_url: '/lottery/wingo'  }
];

const otherGames = [];

const Home = () => {
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState(0);
  const SelectedIcon = gameCategories[selectedId]?.icon || IoColorFilterSharp;
  const [categoryGamesData, setCategoryGamesData] = useState(otherGames);
  const [listColumnCount, setListColumnCount] = useState(3);
  const [loading, setLoading] = useState(false);

  const showTitle = ()=>{
    return false;
  }

  useEffect(() => {
      if([1,6,7].includes(selectedId)){
          setListColumnCount(3);
      }else{
          setListColumnCount(2);
      }
      FetchGameForCategory();
  }, [selectedId]);

  useEffect(() => {
      FetchGameForCategory();
  }, []);

  const FetchGameForCategory = async ()=>{
      try {
          if(selectedId !== 0){
              setLoading(true);
              const url = ` ${apiConfig.GAMES.GAMES_PER_CATEGORY}/${selectedId}`;
              const response = await apiGet(url);
              setCategoryGamesData([]);
              if(response.games){
                  const games = response.games;
                  console.log(games);
                  setCategoryGamesData(games);
              }
          }
      } catch (error) {
          console.error('Error fetching messages:', error);
      } finally {
          setLoading(false); // Turn off loading once the data is fetched
      }
  }

  return (
    <div className={"mainContainer-bottom-extra"}>

      {/*Header*/}
      <div className="HomeHeader">
        <div className="HeaderLeft">
          <img src={Image} className="HeaderImage" />
        </div>
      </div>

      <HomeBanner></HomeBanner>

      <NewsTicker></NewsTicker>

      <GameHomeCategories gameCategories={gameCategories} selectedCategory={selectedId} onChangeCategory={(id)=>{setSelectedId(id)}}></GameHomeCategories>

      {/*Selected Title*/}
      <div className='categoryTitle'>
          <SelectedIcon className='category-mini-icon'/>
          {gameCategories[selectedId].name}
      </div>

        {/*Display Category games*/}
        {
            selectedId === 0 ? (
                <LotteryMenus lotteryGames={lotteryGames} />
            ) : (
                <>
                    {loading ? (
                        <div className="loading-spinner"></div>
                    ) : (
                        <OtherCategoryMenu
                            showTitle={showTitle}
                            listCount={listColumnCount}
                            otherGames={categoryGamesData}
                        />
                    )}
                </>
            )
        }



        <BottomNavigator></BottomNavigator>
    </div>
  );
};


export default Home;
