import React, { useState, useRef, useEffect } from "react";
import "./ImageUpload.css";

function ImageUpload({ initialImage, onImageUpload ,height,width,borderRadius,img}) {
  const [image, setImage] = useState(null);
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    if (initialImage) {
      setImage(initialImage);
    }
  }, [initialImage]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const imgname = event.target.files[0].name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxSize = Math.max(img.width, img.height);
        canvas.width = maxSize;
        canvas.height = maxSize;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          img,
          (maxSize - img.width) / 2,
          (maxSize - img.height) / 2
        );
        canvas.toBlob(
          (blob) => {
            const file = new File([blob], imgname, {
              type: "image/png",
              lastModified: Date.now(),
            });
            setImage(URL.createObjectURL(file));
            onImageUpload(file); 
          },
          "image/jpeg",
          0.8
        );
      };
    };
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <div className="image-upload-container">
      <div className="box-decoration" style={{height:height,width:width}}>
        {/* <label htmlFor="image-upload-input" className="image-upload-label"> */}
          {/* {image ? "Change image" : "Choose an image"} */}
        {/* </label> */}
        <div onClick={handleClick} style={{ cursor: "pointer" }}>
          {image ? (
            <img src={image} alt="upload image" className="img-display-after" style={{height:height,width:width,borderRadius:borderRadius}}/>
          ) : (
            <img src={img} alt="upload image" className="img-display-before" style={{height:height,width:width,borderRadius:borderRadius}} />
          )}
          <input
            id="image-upload-input"
            type="file"
            onChange={handleImageChange}
            ref={hiddenFileInput}
            style={{ display: "none" }}
          />
        </div>
      </div>
    </div>
  );
}

export default ImageUpload;
