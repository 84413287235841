import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Create Axios instance
const api = axios.create({
    headers: {
        'Content-Type': 'application/json',
    }
});


// Request Interceptor to include token in headers
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);


// Response Interceptor to handle 401 and 403 errors (unauthorized or forbidden)
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            const navigate = useNavigate();
            navigate('/login');
        }
        return Promise.reject(error);
    }
);

// Handle response errors or successes
const handleResponse = (response) => {
    if (response.data.status === 'error') {
        const error = new Error(response.data.message || 'An error occurred');
        error.response = response;
        throw error;
    }
    return response.data;
};

// GET Request
export const apiGet = async (url, params = {}) => {
    try {
        const response = await api.get(url, { params });
        return handleResponse(response);
    } catch (error) {
        console.error('GET request error:', error);
        throw error;
    }
};

// POST Request
export const apiPost = async (url, data = {}) => {
    try {
        const response = await api.post(url, data);
        return handleResponse(response);
    } catch (error) {
        console.error('POST request error:', error);
        throw error;
    }
};

// PUT Request
export const apiPut = async (url, data = {}) => {
    try {
        const response = await api.put(url, data);
        return handleResponse(response);
    } catch (error) {
        console.error('PUT request error:', error);
        throw error;
    }
};

// DELETE Request
export const apiDelete = async (url) => {
    try {
        const response = await api.delete(url);
        return handleResponse(response);
    } catch (error) {
        console.error('DELETE request error:', error);
        throw error;
    }
};
