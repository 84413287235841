import React, { useState, useEffect } from 'react';
import './newsTicker.css';
import { apiGet } from "../../services/apiService";
import apiConfig from "../../config/api";

const NewsTicker = () => {
    const [messages, setMessages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const response = await apiGet(apiConfig.COMMON.NEWS_FEED);
                console.log(response);
                const banners = JSON.parse(response.news);
                setMessages(banners);
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessages();
    }, []);

    useEffect(() => {
        if (messages.length > 0) {
            const interval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length);
            }, 5000);

            return () => clearInterval(interval);
        }
    }, [messages]);

    return (
        <div className="news-ticker">
            {messages.length > 0 ? (
                <div className="scrollable-content">
                    {messages[currentIndex]}
                </div>
            ) : (
                <p>Loading messages...</p>
            )}
        </div>
    );
};

export default NewsTicker;
