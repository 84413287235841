import React, { useState } from "react";
import { Outlet, NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import promo from './../../images/promotion.png'
import {
  faHome,
  faTicketAlt,
  faWallet,
  faTrophy,
  faUser,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { BsBagFill } from "react-icons/bs";
import "./BottomNavigator.css";

import { BiSolidShoppingBag } from "react-icons/bi";

const BottomNavigator = () => {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState("home");

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleRouteChange = (routeName) => {
    setSelectedRoute(routeName);
  };

  const showHeader = location.state?.fromConfirmPassword;

  return (
      // <div className="bottom-nav">
        <nav className="nav">
          <NavLink
              to="/"
              className={({isActive}) =>
                  isActive ? "nav-link active" : "nav-link"
              }
              onClick={() => handleRouteChange("home")}
          >
            <FontAwesomeIcon icon={faHome}/>
            <div className="NavText">Home</div>
          </NavLink>

          <NavLink
              to="/activity"
              className={({isActive}) =>
                  isActive ? "nav-link active" : "nav-link"
              }
              onClick={() => handleRouteChange("activity")}
          >
            <BsBagFill/>
            <div className="NavText">Activity</div>
          </NavLink>

          <NavLink
              to="/promotion"
              className={({isActive}) =>
                  isActive ? "nav-link active" : "nav-link"
              }
              onClick={() => handleRouteChange("promotion")}
          >
            <img
                src={promo}
                style={{height: 80, width: 80, position: 'relative', top: -26, left: 3}}
            />
            {/* <FontAwesomeIcon icon={faTrophy} /> */}
            <div style={{position: 'relative', top: -20, left: 5, fontSize: 12}}>Promotion</div>
          </NavLink>

          <NavLink
              to="/wallet"
              className={({isActive}) =>
                  isActive ? "nav-link active" : "nav-link"
              }
              onClick={() => handleRouteChange("wallet")}
          >
            <FontAwesomeIcon icon={faWallet}/>
            <div className="NavText">Wallet</div>
          </NavLink>

          <NavLink
              to="/account"
              className={({isActive}) =>
                  isActive ? "nav-link active" : "nav-link"
              }
              onClick={() => handleRouteChange("account")}
          >
            <FontAwesomeIcon icon={faUser}/>
            <div className="NavText">Account</div>
          </NavLink>
        </nav>
      // </div>
  );
};

export default BottomNavigator;
