import React, { useState } from 'react';

const LanguagePopup = ({ langId, languages, closePopup, onLangChange  }) => {
    const [selectedLang, setSelectedLang] = useState(langId);

    const handleLangChange = (langIndex) => {
        setSelectedLang(langIndex);
        onLangChange(langIndex);
        closePopup();
    };

    return (
        <div className='overlay' onClick={() => closePopup()}>
            <div role="dialog" tabIndex="0" className="popup" style={{ zIndex: 2011 }} onClick={(e) => e.stopPropagation()}>
                <div className="list info">
                    {languages.map((lang, index) => (
                        <div onClick={() => handleLangChange(index)} key={lang.title} className={`item ${selectedLang === index ? 'checked' : ''}`}>
                            <div className="item-title">
                                <span className={lang.class}></span>
                                <span>{lang.title}</span>
                            </div>
                            <div className="radio-group" role="radiogroup">
                                <div
                                    role="radio"
                                    tabIndex="0"
                                    aria-checked={selectedLang === index}
                                    className={`radio ${selectedLang === index ? 'checked' : ''}`}
                                >
                                    <div className={`radio-icon ${selectedLang === index ? 'radio-icon--checked' : ''}`}>
                                        {selectedLang === index && <i className="icon icon-success" />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LanguagePopup;
