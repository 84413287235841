import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
// import Image from "../../Assets/Images/TC.jpg";
// import "react-image-picker-editor/dist/index.css";
// import Images from "../../../Components/Assets/Images/women2.jpg";
// import Images1 from "../../../Assets/Images/Account.png";
// import ReactImagePickerEditor from "react-image-picker-editor";

import "./Account.css";
import { RiLogoutCircleLine } from "react-icons/ri";
import { BiRightArrowAlt } from "react-icons/bi";
import ImageUpload from "../../components/imageUpload/ImageUpload";
import BottomNavigator from "../../components/bottomNavigator/BottomNavigator";

const Account = (props) => {
  const navigate = useNavigate();
  // const [imageSrc, setImageSrc] = useState(null);
  // const bottomContentRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const config2 = {
    borderRadius: "40px",
    language: "en",
    width: "80px",
    height: "80px",
    objectFit: "contain",
    compressInitial: null,
  };
  // const initialImage = Images;

  const handleImageUpload = (file) => {
    setSelectedImage(file);
    console.log(selectedImage);
  };

  return (
    <div className={"AccountContainer"}>
      {/* <div ref={bottomContentRef}> */}
      <div className="AccountHeader">
        <div className="innerheader1">
          {/* <div className="innertext"> */}
          <ImageUpload
            height={75}
            width={75}
            borderRadius={100}
            initialImage={
              "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
            }
            onImageUpload={handleImageUpload}
          />
          {/* </div> */}

          {/* <ReactImagePickerEditor
                        config={config2}
                        imageSrcProp={initialImage}
                        imageChanged={(newDataUri) => { setImageSrc(newDataUri) }} /> */}

          <div className="innertext">
            <div className="inhd22">
              <label htmlFor="name" className="aname">MEMBERSHIP</label>
            </div>
            <div className="inhd4">
              <label htmlFor="name" className="aname">UID</label>
              <label htmlFor="name" className="aname">| 73150</label>
            </div>
            <div className="inhd22">
              <label htmlFor="name" className="aname">Last Login : 24.05.2020</label>
            </div>
          </div>
        </div>
      </div>
      <div className="innerheader2">
        <div className="inhd3">Total balance</div>
        <div className="inhd30">₹ 0.00</div>
        <div className="HorizontalLine"></div>

        <div className="icons">
          <div
            className="firstIcon"
            onClick={() => navigate("/MainLayout/Wallet")}
          >
            <img
              src={
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAeFBMVEUAAAD/oKD/oKD/oaH/n5//n5//oKD/oaH/n5//n5//oKD/n5//oKD/n5/+i4v/n5/+iIj/n5//oaH/n5//n5//oaH/n5/5WVn9hYX6a2v7c3P////8i4v7eXn6YmL5XV3+lpb+j4/+9fX+6ur+m5v8trb7f3/6Zmaz6OagAAAAFnRSTlMA359fIBBvP++vjzC/f9/Pv49/YFBPchRNZwAAANhJREFUSMft1bkSgjAUheEEw467XoQQdvX931CLFBdDSFJYMMNfn2+YYQlkyz7/mnjaLqmyv0WwmMem+wBMUR/vWQTGzhgkYBG+RGYDdgjQtYCxEiXq5AVsCYyiySc9AGiqB20tdxgAxUDdqwBCHSjzebDTgCp3AfgCtSWQu6rnTzsgNwPn/OUC+i+orIB8ZsD5kFuBQo6EqP90W+HtCrrG7dXAAoPI1wJoixkQLH5AnShwhywOydoOgZ/urkclQyAEczHB7cEUZcRJUOUfxLwj1RYHPtly6gOS6nMolZZu1QAAAABJRU5ErkJggg=="
              }
              alt="Image"
              style={{ width: "35px", height: "35px", paddingLeft: "2px" }}
            />
            <div className="AccountText">Wallet</div>
          </div>
          <div className="firstIcon" onClick={() => navigate("/Deposit")}>
            <img
              src={
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAjVBMVEUAAAD3eAD3dwD4dgD5dwD2dgD4dgD0bwD4eQD4dwD4dwD4dwD7dwD5dwD2dgD5dgD4dwDtcQD5dgD2eAD5dwD4dwD4dwD4dwD4dwD4eAD3eAD4dgD/dAD3dQDqbgD5dwD3dgD4dwD5eQD5dwD5dwD4dwD5dwD6dgD4dwD4dgD6eQD7dgD4eAD2dAD4dwAMGSgcAAAALnRSTlMAZsyZxU2mEkxrQN9yn3OMcg19OfPqv6+UWTImHxoI0YZULfiE2HlfuI9fQnM4FNyjywAAAtFJREFUaN7s1u9ymkAUBfDdPYBDKAERkdZ/WFF0THn/x2sTSy5JENiF64eMvxc4c+7smVnx8PDw8O1N7chJlEqcQP6airuZykTV4Oda3IVdxRLsJoKdVA32sf9b8HpWDbzyn20hGEnVJC7feP5KMLFVk235zn8SHNaJajIva7yCoXbQUpjE/or30PS0vhj5jS9Uo33ZwF/dvTBFMxeOyxu8grXwobztPGEsPC9beCuGwrQl3mRH52mR7cBrb/4ojS3VnYW5pR3ipFmYPAlDsxCArzS3RBLD2AyvFi1b4qi8DnFlaW+JqEj7gV1Q0d8SmQO23ksOUUn0C5M9AFej9CRDxTfZEnEA5BODXNMtUTAla+XCMtsSBVOyRu6wLVEw8o3oFoIYPi2C/0LRyUaNY7glmlPl0nlo1J0GFvZQ6fwYRCCmWyIW0PPYM9QtzLZEFiBL0SZDnTWwcIyacHDhQ9nXAehZ+QfG2xJdurvyBF+elnXynU+sHraHuLo02fS89Mlyghdp7JimKT649Lt0cHTlUMeg361BqOswL7XWZ3HDEpU0kmS81uuu787ZlSNy30vPRLMdrnJXjivC1U40c/GGzjx2ctH6qHPJIMer57bgQLIIuoJTV7Io0o7gSDIJ24MzySZqDXYlGzdtCc4ko+PfdukYx2EQCsLwmwI50ESsKyAgmWJX8v0PuE2aFOPYjqeJ+C7wa6TZCHsIeR6+Q+pOw7+Q+qFhDynPwh5inoT/ILaS8AKxhYQdXn1P+DbCI/w0wiM8wiO8OxxAqMMzxAIJd4jNJFwg1kl4gtiDhBvEJiMStIwJkLoZ0yE1GxMhVY1yEErGZQgF4xqEJiPIr8Wf1t+r2qYMkWDbWoJEivbGBIlib3UIZNshi7tcFne5mnChtdtuMeAyLtoR0eESqdhRMayfr612yiMsOG11vdl5rZYc3HJkfEouzCXaMAzDt/kHbN7wdhAVLO0AAAAASUVORK5CYII="
              }
              alt="Image"
              style={{ width: "35px", height: "35px", paddingLeft: "2px" }}
            />
            <div className="AccountText">Deposit</div>
          </div>
          <div className="firstIcon" onClick={() => navigate("/Withdraw")}>
            <img
              src={
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAXVBMVEUAAABcpf9cpv9bpv9cpv9cpv9dpv9cpv9bpf9dpv9ZpP9cpv9cp/9cpf9bpP9Yp/9cp/9ZoP9cpv9cpv9cp/9Ypf9Ynf9Onf9cpv9cpf9bpf9dpv9fpP9ao/9cpv/+vZV2AAAAHnRSTlMAZoxfTb9Z3yY/H/DFeXAazxGWj4I5DQbZvJ9wRkB5MVsiAAABcklEQVRo3u3T3XKCMBCG4Q0/ASMoiIBa3fu/zHac2nQwESzZHHS+5zAHvGwWCAAAAOBJm3Z5wasUeZe19J5NzoHk6TvZhgMqlqZNzoFdzKJxtxxcsaFZKYtIV3RFyxsW8/K2TcFitob8Liwoj77g+csuWFQReeD5kXN2kd+yYXEtuWQsLiWXjsV1cVds5TF/pvkfiiNAGOEHhP9ZWEWAMMIPCCOMMMIII4zwD4QRRhhhhBH2ho+JZU93iUsdLnzY8i/bw3e2YbdqFyhc88TxflyxT7M+7C6c7eu47cKETzxxUl8S9kvChM9PS7yvmP3qv4X19JN2P7dhn0rN0eSSLfyqK3ZrPtScjFx69SSxjva0Tlxs1q8nl1KJM+QyKnEjeZYsLCO3vRJWkodWojRR/JHtwPG3fCO/IVVi9EBWzMtu6aVSWYILli/b7qxWq+D0nhYYbiqwbKBlSh00u6flzDVy1hpNn+mVm7325UgAAAAAU5/94mxKVD2pxAAAAABJRU5ErkJggg=="
              }
              alt="Image"
              style={{ width: "35px", height: "35px", paddingLeft: "2px" }}
            />
            <div className="AccountText">Withdraw</div>
          </div>
          <div className="firstIcon">
            <img
              src={"https://9987up.club/assets/png/VipIcon-3c72b1cc.png"}
              alt="Image"
              style={{ width: "35px", height: "35px", paddingLeft: "2px" }}
            />
            <div className="AccountText">VIP</div>
          </div>
        </div>
      </div>

      {/* <div className="textarea">
        <div className="imgs">
          <img
            src={Images1}
            alt="Image"
            style={{
              width: "60px",
              height: "60px",
            }}
          />
        </div>
        <div style={{ width: "85%" }}>
          <div className="textingarea">
            <div className="headline">Safe</div>
            <div className="no">₹0.00</div>
          </div>
          <div className="line">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet vitae
          </div>
        </div>
      </div> */}

      <div className="fourbox">
        <div className="boxContainer">
          <div className="firstbox" onClick={() => navigate("/BetHistory")}>
            <img
              src={
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAtFBMVEUAAABcp/9cp/9epv9dn/9cpv9cpv9cpf9cp/9bpv9cpv9dp/9cpP9bp/9eqP9apf9Sn/9dpv9Jmv9eqP9ElP9cp/9cpv////82if+t0v/B3f+Zx//W6P/m8f/N4f/0+f9Il/9Ckv9wsf9Rnv9Qmf9Lmf/r9P/a6f+00/86jf+Duf9ao/+jzf9Xo/8+j/+/2v+32P+Pwv9mrP9op//g7v+31/98tv91rv9mq//N4/+jzP+Nvf89OsrBAAAAFnRSTlMAf78gEN/vz29fr58/n28wz49vT09A/ixTGwAAAdVJREFUWMPtlttygjAQhkHAQ221xwSlCSogKJ6t1bbv/16Ngc7KTIBlai+c+t3JzH4uu3802pXzYnbuaoXcdIyicr1OymnnKowGQWHlGaC+zGAq61sETVspsAgeVQsvpAJNhaBZRXCnEOhVBDe/FdSKBdOPVxUOVjCiOfSQgl6eYIwUzPIEb0jBZkyVuOsSAbB2VBzKhljKfxKMVn0FK3wSXarm8+KTSB30EGc9FdMLysEfCuZeMAi8UsHBgcmt4XE8mNgJw/viJGbW2Ccpng0s2gY+SO9EMrAlX+H2KCCWgY7yFOoFjC6P70CEAXGY4AAEstqPmHjCWBjDn7wyiTs4xLuRnP5E1ruZ19IrrHGYtk9Dl7qcRwER1E28QDYQUxoJCU9mIGiiBLDBrRhoRlDDCWCElIb+qaCOE8AOOZWEkZ8IiIkVDBOB+PoEFhCJge8gZboUWxCaFXSAn8FPEvmQUbfiDLyMwF5SuoGbEioHGYHP0ny3cNc8GMKWMXZy2bG0DLdFglhGMaIp3IMGgBopm4LPORdbiPaTOeQQ1wJsUiZxoL70P2AMTri3F+JjA+oxi4DfBMEiJnVdU9KyChWejHRAGrqp5dF90gt5fL7tmtqVs/MN94DbsbuNvhYAAAAASUVORK5CYII="
              }
              alt="Image"
              style={{ width: "42px", height: "42px", paddingLeft: "2px" }}
            />

            <div className="AccountOptions">
              <div className="bigtext">Game History</div>
              <div className="smalltext">My Game history</div>
            </div>
          </div>
          <div className="firstbox" onClick={()=>navigate('/TransactionHistory')}>
            <img
              src={
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAArlBMVEUAAABK461L46xE36RL4q1L465K4q1O4a9K4a1K46xK4qxK4a1K46xL4qxK4q1L4axK4q1K361M4q0T0IE53J8u2JUg2I5L4qz///8GzHal8NW79N+O7MvS9+rg+e4X0oTp/PUo15Al0odi5rdE4KY33J0l04ch1YwLznph5rYQ0H6w8tqa79CC5bpW5LEx2Zcs2JT0/vqD68Z46cHk+vHg+e+P7cvH9uWa7tBt57tTDyxnAAAAF3RSTlMAf78Qn28/H3+vj99g79/PTzDv779vT8FjD08AAAGZSURBVFjD7ZZpb4JAEIaVS8Gj2msArYoCWrxQe/7/P1aWjQTbYXcItompzxcTk3kyzLwLW7tyXlRdU4SYekNU3rwFOVqhonEDJFpFBlZPM6hofRvIaKigC3SwFgwogY4IdIBqz1CHEphVBYpYcHh/wnijCmZ2AS5R4BYJJkTBqkjwShRsJnj9bk8QcPZDjI1siFL+k2C2HCAs6Unc2Tiri0+iPSQP8cPFOFxQDn5R4AdbbxtIBZuhm7HPVXuRw5nfi5N4ssbB8e9g4WTcNUsE6RNSPCfHGDSVHOXDaX24DpkATOph4gcgdo6M7FHIBGDRjvPyOZ3fIidIDEwA/RJrnDsZU2bY8jDQBayBcGRnvKR9qWRB4CSsf3zl2mRBzHvPTTbtwEIEshFkOWCYVQXK3wliB92CVXULvRI5QLeg0q95XpbBkL8QkLueAQL8KDOkAhblzvc7twLCKXADF0Q+dls1QITHDesp+/EAAHknWXIDJ8LqGU2QzIEz9qFl1FDaXRARjJN1LuKOwstR+r26kIdHo1G7cn6+AEIy4spd9Gx2AAAAAElFTkSuQmCC"
              }
              alt="Image"
              style={{ width: "42px", height: "42px", paddingLeft: "2px" }}
            />

            <div className="AccountOptions">
              <div className="bigtext">Transaction</div>
              <div className="smalltext">My Transaction history</div>
            </div>
          </div>
        </div>
        <div className="boxContainer">
          <div className="firstbox" onClick={()=>navigate('/DepositHistory')}>
            <img
              src={
                "https://9987up.club/assets/png/rechargeHistory-195824c7.png"
              }
              alt="Image"
              style={{ width: "42px", height: "42px", paddingLeft: "2px" }}
            />

            <div className="AccountOptions">
              <div className="bigtext">Deposit</div>
              <div className="smalltext">My Deposit history</div>
            </div>
          </div>
          <div
            className="firstbox"
            onClick={() => navigate("/WithdrawHistory")}
          >
            <img
              src={
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAbFBMVEUAAAD/iRv/ihz/hhr/iBr/iRv/iB3/iRv/ihv/iRz/hR3/iRz/iRr/hRT/iR3/ihv/iRr/hxn/iRv/iRv/gCr/iR3/iRz/hxv/iRz/iRz/iBz/ihz/ixv/hRH/iRz/ih3/iBv/iRv/kiT/iR3fckNCAAAAI3RSTlMAZp8mH1/gb1iPEH9NDPU+MBmxeQbvz0Dsxb6oORLZv5aCBztI/qIAAAHtSURBVFjD7djJkoIwEIDhJISwyiqiuIxOv/87ToFV0wQZk9A5zMH/ZFn6VbNEAfbp02+yDMvKn5Z0MNXkPrg0AUxJ+ngKtELPHop0D0W6RxfRM4t0D0W6hyLd08s9e9BJuqc3ePKw0rMHiWcPlAM4gE2ppbaX+VlYdC7rvYVW5dyhONgZuJC7Vr0Z8xrzDcV/DhnwjQXrnuTcq7gLt4Ph2lbHnFBu3IH0jY5pYLz0ak6sXoAlFbwYtpi6zXtObq8vOjp4pR8Tvdqw7IhnYvBPwOgufrvQwfYGs0Iy+CjAK9gW4Bc8gV8wAs9g4hsUvsGHBRjdT8cCQB1EawajwgRGzewjJzPZGMDHEbQa44jqLShg2WASW/UGjOC1EzclbsUfoOxgpfP2ny8FaxXtVjBcUgqmbi6gZNjXgpNMHmAs2vafInXvMF6MV8578Ypgrnkind582g4gw5Lnl/tejB6bKmHsaO/lDBsdUONgGWT6nY89WC3BZnrZs6lKgSu4W4IDw8oOnhXWXv+67rKV01I5D4gHAMXIsJzNV4dpNxczwO6WXvzNtBJAce4dN95L4VJpkgFmNc4ejog5D3j5Zi+lhzWwteHy2vqZgDBiYV7hdCaxiwJD9c78HBP7koycFMj1zEtpnwkhkjBlnxz6AfNNngUB9TYwAAAAAElFTkSuQmCC"
              }
              alt="Image"
              style={{ width: "42px", height: "42px", paddingLeft: "2px" }}
            />

            <div className="AccountOptions">
              <div className="bigtext">Withdraw</div>
              <div className="smalltext">My Withdraw history</div>
            </div>
          </div>
        </div>
      </div>

      <div className="ordinaryfull">
        <div className="ordinarys" onClick={() => navigate("/notification")}>
          <div className="notify">
            <img
              src={
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANrSURBVHgB7Zo/TBRBFMa/t5wQCBjjVRiNWnhUJhQ2UlFZaYmtJiYao7GhsNCS1k5DNLG4VktoNdpARWJiR4NGgzGRSMScinCPN7O3wB1vj9t/d7fH/JLluNndmfnmzXvz5nYBh8PhcDgcnYIQE36FfqxgACcwjHbyH5t0H2uISSTB/Byj2MJ59OGsfB1BJ/FE9LY9PkYZgJYEizWHsY5JVHEK3YiHVbH8O7qH34ddeqhgfoESGJflGEA3w9iUmbdAt7Hc7LKmgnkW5+SKK8gTnli6iWgv7ISdxiSWzRvbmOBn4YE0VLCEgUvodGCKA8nq4dm+q6iCa9YtIa9I3+2yqaBbeL1Lo3EUvmNMK9YF/5O1Nu8UUNSKvZCL25s9ZUEBJ7ViXbDXA4K3cFwr1gVHSTIIQ3KMS01FZIVpw5MlMkobpAetApJgOlClaam8KINUAXFZPj8gTdibkHqnpN4h+WbaeGTbiomHJDDOWLE+0iG6K8c1pIbU5fENv+5aG4QLSEAyC8OmcBXsdchMpavy57SMRjm2JSgYvIZcgCUdYnxFApJauALmGduResbB9DiWX5t7zL2aWPATIP5e2K8+OWviVzPyWe+7VOu4CWitYvzVv6d+oIgXULVtJBJrSENwYOlZ+Weu4UwEvz7gr0Hlc7IPL0tPYweq/ST14XoY82IN8TGq73jg1yQZbrXBSv4UvgkcyN0rdoPKzfe3UUlXsMEuS/xFBE43TE3j1+NStrzr8/5yVlLqSMVfNdIX7BP49ZSommg4V2r6s4Px1228TmsKN5KVYNSWpLIIWGtxbTZxYF7ue5NSZFHJTnCA8WvwovVjprEDERhmQGCs+jYrq+4ne8E+JmEoG/UizOTFg7a0ij+7IjO06n7aJXgPX2BbRdY3f8RwgnsdJ7jXcYItJD/U5h3zcE1BF1w9/LFj11PAL604bEqnvktpOyFGCxP8A3mH8Ekr1gUX0910d4Sf+KYVq4Lpujg851i09J0eYkM7Fb4s9WMpp9F6Q8LVUtjJUMF0S24kLCJvMBbDrGvonZdazGwUAyV6qSWAX2IEfzGJPoyiG2GsyjR+38yyAdFeTHsq8bsPF2HiOGX4tLA1NuTXk8+SYKzQHT0ia8R+9dBgB+CY/lgyM9YloRjEJj3ogfTX4XA4HI4jxg4JLvQQGxoIkwAAAABJRU5ErkJggg=="
              }
              alt="Image"
              style={{ width: "35px", height: "30px", paddingLeft: "2px" }}
            />

            <div className="iconText">Notification</div>
          </div>
          <div className="rightIcon">
            <BiRightArrowAlt className="iconText1" />
          </div>
        </div>
        <div className="HorizontalLine"></div>

        <div className="ordinarys" onClick={() => navigate("/Gift")}>
          <div className="notify">
            <img
              src={
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALTSURBVHgB7Zq9axRBGMaf2ZxXKR5cERQLo9iJXGulhVh5oI1gp1ioWFj6dwhKsBCTSrAKCII2xsZUYrQ6I2okoKSIHJ6I3l3u9dn1FtS72Z3Zj7C7zK+4ney8O5nfzs3H3g7gcDgcJUIhI+Qh6ujiNEbYDw9bGOCJuo7vSADL2s2yTgZlCdbQxAt1Hn1kQCbCQQW/os3knr9O97CNR7bSU8vyb2CDZWUgnVpYIxtiJR1ZVkbSqYRjZEOMpI3KykDaQxriZRHkz6AtdyikwfDGgX26yb7dRgoSC8td7EO8bIhW2lg2hNJym+IJSdfCdkxIW8uG7OKMkJDYPiz32Wt+osXpocXoxj+ZHo7y0/af99hK74JUDUeYtpMVjgX+VDXJsrqG5bjLI4XZGi3U1DMmGygH6xjKOQ6Qq7oArbDM4yA89QrlkQ3poi5z6hKPU9D3YcFZlE/Wp8FJ66IuUy9cUydQVoTLUg1Ro3QZWzdkry5jJ6elQuCEq45eeFs+oby81mVEtfACyopgSZelFQ6WaSO1iLIhcitqiRm/lp7nJO7hBkNbKC5diq6yZRcpuxAVaPQDgNzjAn+AC7DjOIs/jCSIrLBm7y2u6KmreGAS6EbpquOEq44TrjpOuOo44arjhKtODfkxmHKuDyUdHjt8sqnzAeEQRB2biFLZvPyeRp7CHVb8AMXG75Jkkx8rwauSP/SZfsPzH6DUqf/iNpATeQpTTB7zOBu0mGBTGyeyxJjZ4C99XCbkKexX3v9qbvBoEpuraIgbpauOE646RsLqMt/aVwTzFlb4haIyY143c2HJb/WTmpH5bj8b4S8oKn3zupkLe3iLovID66ahxsLqSnAXizd4CdbUTfN62U1LQzwt2ODVwze8tLnAenPp+D3TGdjunssawWfKPrdpXZ/Eu2mDvZZDzDHZ5LTgP9rlfQP8Xblb8J+uavg47mIOh8NRbX4DRmHeTbIJf+AAAAAASUVORK5CYII="
              }
              alt="Image"
              style={{ width: "35px", height: "30px", paddingLeft: "2px" }}
            />

            <div className="iconText">Gifts</div>
          </div>
          <div className="rightIcon">
            <BiRightArrowAlt className="iconText1" />
          </div>
        </div>
        <div className="HorizontalLine"></div>

        <div className="ordinarys" onClick={() => navigate("/GameStatistics")}>
          <div className="notify">
            <img
              src={
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFwSURBVHgB7do/TsMwFAbwz+GPxMYN6MSMxMQGE2uvgBBCsMANCDfoxp8ycAOQuAAbExdgysbKjKgfjzA1OE4lrCh++n5DW8WulS+x/CK3ABFRRhwyI7fFGURK/bjeaPpA4SbuyF/Gvp9VYLnBWE/5IdrJy547wXNbc4GceGwt0Gs31phX4AQY2DoGtm4ZPflv/Uyllztc10+RCf6GRX3MSylX8XKSSj9TOkH9TIWLlnUMbB3rcJNMiwstG+cIlZTClX3Vz1Sid7iunz74sPCrx/qZSnxKD6h+psJFyzoGto6BrWNg6xjYOga2joGtY2DrGNg6BraOgeeI/nbbPUJ3nwGNEw+8hnt9rSI9Kt3KfUSXAY3T+cc03Wgf6ZUda8/5zfifq60n4A4WuOoDHIeIKAutq7RMsY0ZNpGjFVTuEC+hpmBgucMGvrCPnDk8uWO8Nw+HHzxm+ETulrAaOtw+pa+xo28j5MjjzZ3iFUT2fQN7N25AHhz/GwAAAABJRU5ErkJggg=="
              }
              alt="Image"
              style={{ width: "35px", height: "30px", paddingLeft: "2px" }}
            />

            <div className="iconText">Game statistics</div>
          </div>
          <div className="rightIcon">
            <BiRightArrowAlt className="iconText1" />
          </div>
        </div>
        <div className="HorizontalLine"></div>

        <div className="ordinarys">
          <div className="notify">
            <img
              src={"https://9987up.club/assets/png/languageIcon-634b6df2.png"}
              alt="Image"
              style={{ width: "35px", height: "30px", paddingLeft: "2px" }}
            />

            <div className="iconText">Language</div>
          </div>
          <div className="rightIcon">
            <BiRightArrowAlt className="iconText1" />
          </div>
        </div>
        <div className="HorizontalLine"></div>
        <br />
      </div>

      <div className="services">
        <div className="serviceHead">Service Center</div>
        <div className="imgs1">
          <div className="imgs">
            <img
              src={"https://9987up.club/assets/png/settingCenter-61690292.png"}
              alt="Image"
              style={{
                width: "40px",
                height: "40px",
                // marginTop: "40px",
                // paddingLeft: "5px",
                // marginRight: "5px",
              }}
            />
            <div className="ServiceText">Settings</div>
          </div>
          <div className="imgs" onClick={() => navigate("/Feedback")}>
            <img
              src={
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAL4SURBVHgB7Zo9bxNBEIbfsR1LIJAAV6SBNFAhIoUmVJEQJS1FmiQCia8fQEHBL6BEkJAitNS0oNCQhoggV0kDyMgREgGLICMn8Q2ziwskK96985zPZ/aR7Dvr1nvz3szezO0tEAgEAjmCkAL8BDMo0kMwJuXnCcSjIVZtoM0LdAefoIy6YF4uzqEdrUCDIs/TTTyHIqqCxbNnUaD3iO/Vw2igzBO0IFslCtBlBnpiYfvawzwUKaEPeBGn5ZKNI0JFxmtF9udkqwvhKi+hJnstHGBb+t+ke/iFhCQSbIUSpkToONr/HkAZ2kQ4Kd8Vu28uLjAlF6COfawmER47pPmZCGVcs2Kzwpy7iFlrS0xiCbYnaPc8yW9oQ2geekxsiSvaW7CHWGNcDdoQNnseN6IXcQGeeAnmFzjmFGsb4oN8dqCF6SuSPt3tpvmx2OiBn4d3cMmrHZvw40cqom0f0pcvYzYlOnEWHta73zGLeJgUdU6Mvoz4mDFbk4h6LX00Y/3zFFboumTuHrjTUiPR3diE4pps15CUJCXRV5yX72p/3bYwgbxQ6uTrHrgFj6VQTKRFwW2rdi2dLdH/JtiDIHjUCYJHnSB41OlriqcLkvqZ3NWOJ1uSV/WevDroCSYp24ErinNaTRA/+PsEpodmSE9Cl6Mi9iKUURTMb6GL8ewWlNELacZLCcEvMoaPQIMDmeko6IazQfemxfJOSGsMp5Q/Qh4edYLgUSfbSstMxbJ+6unFEFRaMhEMvMKAGIJKi6YxQLKvtIg3MECyrbRyPYYNmpVWSoQ83AVjF/nBuQTCx8PfkBeKqLuauAWX8Rl54Qe2XU2cgumGDWlnR5nDqNN99/Dzu2kR3mHY+Yk3Ps28BNMt6+EqhpUI6z7eNXinJbotb/MLjhU1WSA20V2s+zaPvbiUn8LUvt7LhFKmah0Rg0SraXlJHgMju4zpOLJhVyxf7Qy1WPS1fJiXcQb7dg1IRfGNQzeEltyF99CWFFnCxyRCA4FAIJf8Af1DzvrSv8KKAAAAAElFTkSuQmCC"
              }
              alt="Image"
              style={{
                width: "40px",
                height: "40px",
                // marginTop: "40px",
                // paddingLeft: "5px",
                // marginRight: "5px",
              }}
            />
            <div className="ServiceText">Feedback</div>
          </div>
          <div className="imgs" onClick={()=>navigate('/notification')}>
            <img
              src={
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALgSURBVHgB7Zq/ixNBFMe/s8kFEZWAjeliE0sPrOxy2t6hNtZ3zR2Hdv4B5sBWsFCOpFH/AoWzN901FtqmWoigjSAIgkcyzzdzUSNcdnaz82MJ84HdbHY3uf3cezN5M7tAJBKJRCKVQaAi0CHa/NIFoclX1TzjlKHYxxAlqSMgLNlFPbkDSdv8tpl5siS1HqIkQYS1aE085mh2ZyLe8Cqs07YmXmpRv55/8SZML7CORLxh0TYCksAD1Mdd1MV73mwjMM6FdWTBaWzqlDzhNKV1m1VpXBFZhVGYejiPK7XbqNEtSN4uBHVRgTSexxzhVrLPF95h2WII/gyhg4qRKUwDLH/RhBuwiUg6NJCbc3s+4wRj8RDfUABThJeMELV4dRFWURmTbP23a031E+IY0+lRXnFXvfQ1+CKhm2gkj+g5Luc6HW5owSfEskq6b+5UHQiT+k9bTuc8f5alZe2+6TT7wgIXEApOb1OU7QtLXEJICNezDrtoww2EJMnuvLwMHqqEC+EThERinHXYvnCCXwjJhCuwDBxEWHxBKKQ8NlVc9oUJP3jtP8rEolMcmU5z1WmN4BMlO5FP89TTroRT+ILTGF/lk7yDB9NoaclIqXZMqi1brKnFCEL+S9kaC47xSfTws8CXZAuLXYy4VFPSywwTP/CyBVuQHPH1vENJcsxpyUOujzchk3V+5SqGds2fEQM3US5P4XtL1BfGKXSxR/p7Z5N4dqZnJR3wvaUeSuK0tOQLTDGhe7z5HRXBeS0tHuAjx3oHFZH2MngQe3jLkd6Az5+rBXgbLelIS5aW9BoB8To8VG2al22WVimeIgBBxsMs/Yp78qtzEU/hiaBPAMweYRiqYljfdKvzz9eUlwWPPMACQYXnOe3N9eKUOMWz6kThVScKrzpROAdpyeNBKS5sKv4DDw5MFBc+h2dYVBEJ3n96vLIUFhY7PJBv6KL/AH/SlyjV79doQx+PRCKRiB1+AweR1uvbjNhiAAAAAElFTkSuQmCC"
              }
              alt="Image"
              style={{
                width: "40px",
                height: "40px",
                // marginTop: "40px",
                // paddingLeft: "5px",
                // marginRight: "5px",
              }}
            />
            <div className="ServiceText">Notification</div>
          </div>
        </div>

        <br />

        <div className="imgs1">
          <div className="imgs" onClick={() => navigate("/CustomerService")}>
            <img
              src={"https://9987up.club/assets/png/serviceCenter-3789e366.png"}
              alt="Image"
              style={{
                width: "40px",
                height: "40px",
                // marginTop: "40px",
                // paddingLeft: "5px",
                // marginRight: "5px",
              }}
            />
            <div className="ServiceText">24/7 Customer service</div>
          </div>
          <div className="imgs" onClick={() => navigate("/BeginnersGuide")}>
            <img
              src={
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANHSURBVHgB7ZpNaxNBGMf/swlVSwOVXmztIb3Uk7TSLxDQu0LBg6f2YvEF9BuoePIgFFG0BbG9elLqtZJePClEPNlTTvbUUqgUW9k8/mdioWl3s7PJNtls5wdLNrOzu/nN88xkXwZwOBwOR3pRURXkGQo4j2H42IeHPa5vqptcTwHyDoPYRRF5LjVss6iq7qDabJ9QYXmPAR6ixAONBGze4Z6b3PaLDbGh7nO9A8grTPK8JeS9CYiUWFQ8VkmpJfi1J2HigcKU7cMWprlagB07EGwwA36qOX4miLw2gtdRkxl+HbTcrYo+uaJmTdQbyAdW38EU7GVh6iougnF581/ex49WI39MsiaISRF/8JCfj49uUCEnnOaWIbSLx5QH1tVtrNtUlwXcYEo+YIOV0D5VNSdjRwuDI5yErKbe/0cY9SnKfwsTZwPPwFOPoCMTO5ihFIMK8+gMBcqXjLiPFXUPv3WhSd0cRZOJqBWdEj6ggBxuyaJOdfWUopMJRtQKD91Ap7qW7QLdEe4iTjjrnDrh+KN0DqPw1bXQ7UoqHJAqSCnxhX11t/mFiZqg9Dqld5FC4qb0kMVVWD+XUaSUuMK8GZCVpjWUfGF0ra6du0H8lBZ84t3LZzbVuZDtHbk3bpXWLi090z9T2UejcH9LltjcPqYyteMLKz7CA65G1hOsmodFKSNeSivzlxMtW6+r6yXzICFB4vdh21G4Xi91aR0vpc3Vkzznp45ef5OaegRfRQppZdDSUUtd37TF3R5mHSecdZxw1nHCWccJZ51gYYU99D7bQYXBwoIt9Doia0HFYRH+il5HzJSHYwQKm4kpHsroVWoyG2sWzwHyli+w980ElyE2wABb7QySQqkFJAf7q1T5+9a4zDebqxU5Me0w8pLiZym+h4vQjZDDMFqldeFt9k/97uo7f32ZL9crUZPRGk6LNpAXjHg/LuAvxngkLW8/1SmOsDB6wEdG7wMbvBI0/8r6tEgQkwE5XLaSjxIWk6LLXCszgmUkRKLCh5EFStdwiWcYD6wQLKzTdVlHMknJhtPihDEDn2/kG2f3NQpXObIuM13n20lXG05c+DCyyGgfiNeFtaieCLqELKPT3UxKczgcDoejgX+TH/B4o6gbRQAAAABJRU5ErkJggg=="
              }
              alt="Image"
              style={{
                width: "40px",
                height: "35px",
                // marginTop: "40px",
                // paddingLeft: "5px",
                // marginRight: "5px",
              }}
            />
            <div className="ServiceText">Beginner's Guide</div>
          </div>
          <div className="imgs" onClick={() => navigate("/AboutUs")}>
            <img
              src={"https://9987up.club/assets/png/about-eb7d02f7.png"}
              alt="Image"
              style={{
                width: "40px",
                height: "35px",
                // marginTop: "40px",
                // paddingLeft: "5px",
                // marginRight: "5px",
              }}
            />
            <div className="ServiceText">About us</div>
          </div>
        </div>
      </div>

      <div className="logout">
        <div className="logoutButton">
          <RiLogoutCircleLine />
          Log out
        </div>
      </div>

      {/* <div className="services1">
                <div className="head1">S</div>
            </div><div className="services1">
                <div className="head1">S</div>
            </div><div className="services1">
                <div className="head1">S</div>
            </div> */}

      {/* </div> */}

      <BottomNavigator></BottomNavigator>

    </div>
  );
};

export default Account;
