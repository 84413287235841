import React from 'react';
import './LotteryMenus.css';
import {useNavigate} from "react-router-dom";

const LotteryMenus = ({ lotteryGames }) => {
    const navigate = useNavigate();

    const onClickMenu = (index)=>{
        navigate(lotteryGames[index].launch_url);
        console.log("Selected Lottery game: ", index);
    }

    return (
        <div className="lottery-menu-grid">
            {lotteryGames.map((category, index) => (
                <div className={`lottery-game-card lottery-game-card-${index}`} onClick={() => {
                    onClickMenu(index)
                }} key={index}>
                    <span className="lottery-game-card-title">{category.name}</span>
                    <span className="lottery-description">
                        {category.description.split('\n').map((line, index) => (
                            <span key={index}>
                                 {line}
                                <br />
                            </span>
                        ))}
                    </span>
                    <img src={category.image} alt={category.name} className={category.name.toLowerCase()}/>
                </div>
            ))}
        </div>
    );
};

export default LotteryMenus;
