import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Wallet.module.css";
import BottomNavigator from "../../components/bottomNavigator/BottomNavigator";
// import Images from "../../../Components/Assets/Images/women4.jpg";

const Wallet = (props) => {
  let navigate = useNavigate();
  // const images = [
  //   Images,
  //   Images,
  //   Images,
  //   Images,
  //   Images,
  //   Images,
  //   Images,
  //   Images,
  //   Images,
  //   Images,
  //   Images,
  //   Images,
  //   Images,
  //   Images,
  //   Images,
  //   Images,
  //   Images,
  //   Images,
  // ];

  const textContent = [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ];

  const textContent1 = [
    "Lottery",
    "TB_Chess",
    "Wickets9",
    "CQ9",
    "MG",
    "JDB",
    "DG",
    "CMD",
    "SaBa",
    "IM",
    "JILI",
    "Card365",
    "V8Card",
    "PG",
    "WM_Video",
    "TB",
    "EVO_Video",
    "AG_Video",
  ];
  return (
    <div className={styles.WalletContainer}>
      <div className={styles.walletname}>Wallet</div>
      <div className={styles.header}>
        <div className={styles.imgContainer}>
          <img
            src={"https://9987up.club/assets/png/wallets-55b46543.png"}
            alt="Image"
            style={{
              width: "50px",
              height: "50px",
              marginTop: "5px",
              paddingLeft: "5px",
              marginRight: "5px",
            }}
          />
        </div>
        <div className={styles.rs}>₹ 0.00</div>
        <div className={styles.totalBalance}>Total balance</div>

        <div className={styles.wholeAmount}>
          <div className={styles.leftamount}>
            <div className={styles.amt}>0</div>
            <div className={styles.amtWord}>Total amount</div>
          </div>

          <div className={styles.rightamount}>
            <div className={styles.amt}>0</div>
            <div className={styles.amtWord}>Total amount</div>
          </div>
        </div>
      </div>
      <div className={styles.middle}>
        <div className={styles.circle}>
          <div className={styles.textCircle}>
            <div className={styles.innercircle}>0%</div>
            <div className={styles.innertext}>
              <div className={styles.midrs}>₹ 0.00</div>
              <div className={styles.midBalance}>Deposit Wallet</div>
            </div>
          </div>

          <div className={styles.textCircle}>
            <div className={styles.innercircle}>0%</div>
            <div className={styles.innertext}>
              <div className={styles.midrs}>₹ 0.00</div>
              <div className={styles.midBalance}>Winning Wallet</div>
            </div>
          </div>
        </div>
        <div className={styles.transfer}>
          <div className={styles.tranfertext}>Main wallet transfer</div>
        </div>
        <div className={styles.icons}>
          <div className={styles.firstIcon} onClick={() => navigate('/Deposit')}>
            <div className={styles.imgcontainer}>
              <img
                src={
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAjVBMVEUAAAD3eAD3dwD4dgD5dwD2dgD4dgD0bwD4eQD4dwD4dwD4dwD7dwD5dwD2dgD5dgD4dwDtcQD5dgD2eAD5dwD4dwD4dwD4dwD4dwD4eAD3eAD4dgD/dAD3dQDqbgD5dwD3dgD4dwD5eQD5dwD5dwD4dwD5dwD6dgD4dwD4dgD6eQD7dgD4eAD2dAD4dwAMGSgcAAAALnRSTlMAZsyZxU2mEkxrQN9yn3OMcg19OfPqv6+UWTImHxoI0YZULfiE2HlfuI9fQnM4FNyjywAAAtFJREFUaN7s1u9ymkAUBfDdPYBDKAERkdZ/WFF0THn/x2sTSy5JENiF64eMvxc4c+7smVnx8PDw8O1N7chJlEqcQP6airuZykTV4Oda3IVdxRLsJoKdVA32sf9b8HpWDbzyn20hGEnVJC7feP5KMLFVk235zn8SHNaJajIva7yCoXbQUpjE/or30PS0vhj5jS9Uo33ZwF/dvTBFMxeOyxu8grXwobztPGEsPC9beCuGwrQl3mRH52mR7cBrb/4ojS3VnYW5pR3ipFmYPAlDsxCArzS3RBLD2AyvFi1b4qi8DnFlaW+JqEj7gV1Q0d8SmQO23ksOUUn0C5M9AFej9CRDxTfZEnEA5BODXNMtUTAla+XCMtsSBVOyRu6wLVEw8o3oFoIYPi2C/0LRyUaNY7glmlPl0nlo1J0GFvZQ6fwYRCCmWyIW0PPYM9QtzLZEFiBL0SZDnTWwcIyacHDhQ9nXAehZ+QfG2xJdurvyBF+elnXynU+sHraHuLo02fS89Mlyghdp7JimKT649Lt0cHTlUMeg361BqOswL7XWZ3HDEpU0kmS81uuu787ZlSNy30vPRLMdrnJXjivC1U40c/GGzjx2ctH6qHPJIMer57bgQLIIuoJTV7Io0o7gSDIJ24MzySZqDXYlGzdtCc4ko+PfdukYx2EQCsLwmwI50ESsKyAgmWJX8v0PuE2aFOPYjqeJ+C7wa6TZCHsIeR6+Q+pOw7+Q+qFhDynPwh5inoT/ILaS8AKxhYQdXn1P+DbCI/w0wiM8wiO8OxxAqMMzxAIJd4jNJFwg1kl4gtiDhBvEJiMStIwJkLoZ0yE1GxMhVY1yEErGZQgF4xqEJiPIr8Wf1t+r2qYMkWDbWoJEivbGBIlib3UIZNshi7tcFne5mnChtdtuMeAyLtoR0eESqdhRMayfr612yiMsOG11vdl5rZYc3HJkfEouzCXaMAzDt/kHbN7wdhAVLO0AAAAASUVORK5CYII="
                }
                alt="Image"
                style={{ width: "40px", height: "40px" }}
              />
            </div>
            <div className={styles.text11}>Deposit</div>
          </div>
          <div className={styles.firstIcon} onClick={() => navigate('/Withdraw')}>
            {/* <PiHandDepositThin /> */}
            <div className={styles.imgcontainer}>
              <img
                src={
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAXVBMVEUAAABcpf9cpv9bpv9cpv9cpv9dpv9cpv9bpf9dpv9ZpP9cpv9cp/9cpf9bpP9Yp/9cp/9ZoP9cpv9cpv9cp/9Ypf9Ynf9Onf9cpv9cpf9bpf9dpv9fpP9ao/9cpv/+vZV2AAAAHnRSTlMAZoxfTb9Z3yY/H/DFeXAazxGWj4I5DQbZvJ9wRkB5MVsiAAABcklEQVRo3u3T3XKCMBCG4Q0/ASMoiIBa3fu/zHac2nQwESzZHHS+5zAHvGwWCAAAAOBJm3Z5wasUeZe19J5NzoHk6TvZhgMqlqZNzoFdzKJxtxxcsaFZKYtIV3RFyxsW8/K2TcFitob8Liwoj77g+csuWFQReeD5kXN2kd+yYXEtuWQsLiWXjsV1cVds5TF/pvkfiiNAGOEHhP9ZWEWAMMIPCCOMMMIII4zwD4QRRhhhhBH2ho+JZU93iUsdLnzY8i/bw3e2YbdqFyhc88TxflyxT7M+7C6c7eu47cKETzxxUl8S9kvChM9PS7yvmP3qv4X19JN2P7dhn0rN0eSSLfyqK3ZrPtScjFx69SSxjva0Tlxs1q8nl1KJM+QyKnEjeZYsLCO3vRJWkodWojRR/JHtwPG3fCO/IVVi9EBWzMtu6aVSWYILli/b7qxWq+D0nhYYbiqwbKBlSh00u6flzDVy1hpNn+mVm7325UgAAAAAU5/94mxKVD2pxAAAAABJRU5ErkJggg=="
                }
                alt="Image"
                style={{ width: "40px", height: "40px" }}
              />
            </div>
            <div className={styles.text11}>Withdraw</div>
          </div>
          <div className={styles.firstIcon} onClick={() => navigate('/DepositHistory')}>
            <div className={styles.imgcontainer}>
              <img
                src={
                  "https://9987up.club/assets/png/rechargeHistory-195824c7.png"
                }
                alt="Image"
                style={{ width: "40px", height: "40px" }}
              />
            </div>
            <div className={styles.text11}>Deposit history</div>
          </div>
          <div className={styles.firstIcon} onClick={() => navigate('/WithdrawHistory')}>
            <div className={styles.imgcontainer}>
              <img
                src={
                  "https://9987up.club/assets/png/withdrawHistory2-840eb5de.png"
                }
                alt="Image"
                style={{ width: "40px", height: "40px" }}
              />
            </div>
            <div className={styles.text11}>Withdraw history</div>
          </div>
        </div>

        <div className={styles.transfer} onClick={() => navigate('/Withdraw')}>
          <div className={styles.tranfertext}>Withdraw</div>
        </div>

        <div className={styles.transfer} onClick={() => navigate('/Deposit')}>
          <div className={styles.tranfertext}>Add Cash</div>
        </div>
      </div>

      <div className={styles.container}>
        {[...Array(6)].map((_, rowIndex) => (
          <div key={rowIndex} className={styles.row}>
            {[...Array(3)].map((_, colIndex) => (
              <div key={colIndex} className={styles.item}>
                <div
                  className={styles.image}
                  // style={{
                  //   backgroundImage: `url(${images[rowIndex * 3 + colIndex]})`,
                  // }}
                >
                  <div className={styles.textMap}>
                    <div className={styles.textMap1}>
                      {textContent[rowIndex * 3 + colIndex]}
                    </div>
                    <div className={styles.textMap2}>
                      {textContent1[rowIndex * 3 + colIndex]}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <BottomNavigator/>
    </div>
  );
};

export default Wallet;
