import React, { useState } from 'react';
import './ForgotPassword.css'

function ForgotPassword() {
const [email, setEmail] = useState('');

const handleForgotPassword = (e) => {
e.preventDefault();

alert(`Password reset link sent to ${email}`);
};

return (
<div className="auth-page">
    <h1>Forgot Password</h1>
    <form onSubmit={handleForgotPassword}>
        <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
        />
        <button type="submit">Reset Password</button>
    </form>
</div>
);
}

export default ForgotPassword;
