import React, {useContext, useEffect, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {MdCardGiftcard, MdGifBox, MdPhoneAndroid} from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { MdOutlineSupportAgent } from "react-icons/md";
import { BsPhoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { MdScreenShare } from "react-icons/md";
import {IoIosArrowBack, IoMdEye} from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
import LanguagePopup from "../../components/languagePopup/languagePopup";
import splash from './../../images/logo_2.png'
import {apiPost} from "../../services/apiService";
import API_ENDPOINTS from "../../config/api";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {saveLanguage, saveUser, getUser, myLanguage} from "../../services/authService";
import {UserContext} from "../../store/UserContext";


function Register() {
    const { state, dispatch } = useContext(UserContext);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [selectedValue, setSelectedValue] = useState(false);
    const [registerBtn, setRegisterBtn] = useState(true);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [inviteCode, setInviteCode] = useState("");
    const [langId, setLangId] = useState(0);
    const [showLanguageSelection, setShowLanguageSelection] = useState(false);
    const popupRef = useRef(null);
    const [activeTab, setActiveTab] = useState("phone");
    const navigate = useNavigate();

    const languages = [
        { class: 'lang-en', title: 'EN'},
        { class: 'lang-hi', title: 'HI'}
    ];

    //Check User Auth State
    useEffect(() => {
        if (state.isAuthenticated) {
            console.log("User Logged");
            navigate('/');
        }
    }, [state.isAuthenticated, navigate]);

    const handleChange = (e) => {
        const value = e.target.value;
        const key = e.target.name;
        if(key === 'phone'){
            setPhone(value);
        }else{
            setEmail(value)
        }
        checkAllValues();
    };

    const checkAllValues = ()=>{

        let hit = true;
        let emailOrPhone = false;

        if(activeTab === 'phone' && phone){
            emailOrPhone = true;
        }else if(activeTab === 'email' && email){
            emailOrPhone = true;
        }
        if(!selectedValue && emailOrPhone && password && confirmPassword){
            hit = false;
        }
        setRegisterBtn(hit);

    }

    const handlePasswordChange = (ev) => {
        setPassword(ev.target.value);
        checkAllValues();
    };

    const handleConfirmPasswordChange = (ev) => {
        setConfirmPassword(ev.target.value);
        checkAllValues();
    };

    const handleRadioChange = () => {
        setSelectedValue(!selectedValue);
        checkAllValues();
    };

    const HandleEye = () => {
        setPasswordVisible(!passwordVisible);
    };

    const HandleEyeConfirm = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    const handleLangChange = (langIndex) => {
        setLangId(langIndex);
        saveLanguage(langIndex);
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            if (password === confirmPassword) {
                    const loginData = activeTab === "phone" ? { type: activeTab, username: phone, password,  confirm_password:confirmPassword} : { type: activeTab, username: email, password,  confirm_password:confirmPassword };
                    const response = await apiPost(API_ENDPOINTS.AUTH.REGISTER, loginData);
                    toast.success(response.message);
                    if(response.data.token){
                        saveUser(response.data.user, response.data.token);
                        dispatch({
                            type: 'LOGIN',
                            payload: {
                                token: response.data.token,
                                user: response.data.user
                            }
                        });
                        navigate('/');
                    }else{
                        toast.error('An error occurred during registration');
                    }
            } else {
                toast.error("Passwords don't match");
            }
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.message || 'An error occurred during registration');
            } else {
                toast.error('Network error, please check your connection');
            }
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className={"mainContainer"}>

            <ToastContainer />

            <div className={"top-header"}>
                <span onClick={() => navigate("/")}><IoIosArrowBack className={"backButtonStyle"}/></span>
                <span className="logo-header">
                        <img src={splash} alt="Arena Betz"/>
                    </span>
                <span className="lang-header" onClick={() => {
                    setShowLanguageSelection(true)
                }}>
                        <span className={`${languages[langId].class}`}></span>
                        <span className='lang-title'>{languages[langId].title}</span>
                    </span>

            </div>
            <div className={"headerContainer"}>
                <div className={"titleContainer"}>
                    <div className="title">Register</div>
                    <div className={"description"}>
                        Please register by phone number or email
                    </div>
                </div>
            </div>
            <div className="tabRowStyle">
                <div
                    className={`tabStyle ${activeTab === "phone" ? "activeTab" : ""}`}
                    onClick={() => handleTabChange("phone")}
                >
                    <BsPhoneFill className={"headIconStyle"}/>
                    <div className={"titleContainer1"}>Register your phone</div>
                </div>
                <div
                    className={`tabStyle ${activeTab === "email" ? "activeTab" : ""}`}
                    onClick={() => handleTabChange("email")}
                >
                    <MdEmail className={"headIconStyle"}/>
                    <div className={"titleContainer1"}>Email registration</div>
                </div>
            </div>

            <div className={"inputContainer"}>
                <div className={"row"}>
                    {activeTab === "phone" ? (
                        <MdPhoneAndroid className={"iconStyle"}/>
                    ) : (
                        <MdEmail className={"iconStyle"}/>
                    )}
                    <div className={"titleContainer2"}>
                        {activeTab === "phone" ? "Phone number" : "Email"}
                    </div>
                </div>
                <div className="rowOfPH">
                    {activeTab === "phone" ? <div className="Phcode">+91</div> : null}
                    <input
                        name={activeTab === "phone" ? "phone" : "email"}
                        value={activeTab === "phone" ? phone : email}
                        placeholder={
                            activeTab === "phone"
                                ? "Please Enter the phone number"
                                : "Please Enter your email"
                        }
                        onChange={handleChange}
                        className={"inputBox"}
                    />
                    <label className="errorLabel">{emailError}</label>
                </div>
            </div>
            <div className={"inputContainer"}>
                <div className={"row"}>
                    <RiLockPasswordFill className={"iconStyle"}/>
                    <div className={"titleContainer2"}>Set password</div>
                </div>
                <div className="inputBox">
                    <input
                        value={password}
                        type={passwordVisible ? "text" : "password"}
                        placeholder="Set password"
                        onChange={handlePasswordChange}
                        className={"placeHolder"}
                    />
                    <div className="eyeIconContainer" onClick={HandleEye}>
                        {passwordVisible ? (
                            <IoMdEye className={"eyeIconStyle"}/>
                        ) : (
                            <IoMdEyeOff className={"eyeIconStyle"}/>
                        )}
                    </div>
                    <label className="errorLabel">{passwordError}</label>
                </div>
            </div>

            <div className={"inputContainer"}>
                <div className={"row"}>
                    <RiLockPasswordFill className={"iconStyle"}/>
                    <div className={"titleContainer2"}>Confirm password</div>
                </div>
                <div className="inputBox">
                    <input
                        value={confirmPassword}
                        type={confirmPasswordVisible ? "text" : "password"}
                        placeholder="Confirm password"
                        onChange={handleConfirmPasswordChange}
                        className={"placeHolder"}
                    />
                    <div className="eyeIconContainer" onClick={HandleEyeConfirm}>
                        {confirmPasswordVisible ? (
                            <IoMdEye className={"eyeIconStyle"}/>
                        ) : (
                            <IoMdEyeOff className={"eyeIconStyle"}/>
                        )}
                    </div>
                </div>

                <label className="errorLabel">{passwordError}</label>
            </div>

            <div className={"inputContainer"}>
                <div className={"row"}>
                    <MdCardGiftcard className={"iconStyle"}/>
                    <div className={"titleContainer2"}>Invite code</div>
                </div>
                <input
                    value={inviteCode}
                    placeholder="Please enter the invitation code"
                    onChange={(ev) => setInviteCode(ev.target.value)}
                    className={"inputBox"}
                />

                <label className="errorLabel">{passwordError}</label>

                <div className="radioRow" onClick={handleRadioChange}>
                    <input
                        type="radio"
                        id="customRadio"
                        className="radio"
                        value="option1"
                        checked={selectedValue}
                        onClick={handleRadioChange}
                    />
                    <label htmlFor="customRadio" className={`radioLabelRegister ${selectedValue ? 'checked' : ''}`}>
                        I have read and agree [Privacy Agreement]
                    </label>
                </div>


            </div>

            <br/>
            <div className={"inputContainer1"}>
                <button className={`primaryButton ${registerBtn ? 'disabledButton' : ''}`} disabled={registerBtn} onClick={handleRegister}>
                    Register
                </button>
                <button className={"primaryButtonOutline"} onClick={() => navigate('/login')}>
                    <label className="primaryButtonOutline1">I have an account</label>
                    <label className="primaryButtonOutline2">Login</label>
                </button>
            </div>


            {/*Language Selector*/}
            {
                showLanguageSelection && (
                    <div ref={popupRef}>
                        <LanguagePopup langId={langId} languages={languages}
                                       closePopup={() => setShowLanguageSelection(false)}
                                       onLangChange={handleLangChange}></LanguagePopup>
                    </div>
                )
            }

        </div>

    );
}

export default Register;
