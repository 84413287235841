import React, { useState, useEffect } from 'react';
import './HomeBanner.css';
import {apiGet} from "../../services/apiService";
import apiConfig from "../../config/api";

const images = [
    '../../../images/banners/1.jpg'
];

const HomeBanner = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [imagesData, setImagesData] = useState(images);


    useEffect(() => {
        const fetchBanners = async () => {
            try {
                const response = await apiGet(apiConfig.COMMON.BANNERS);
                console.log(response);
                const banners = JSON.parse(response.banners);
                setImagesData(banners);
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchBanners();
    }, []);

    useEffect(() => {
        if(imagesData.length > 0) {
            const interval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % imagesData.length);
            }, 3000);

            return () => clearInterval(interval);
        }
    }, [imagesData]);

    return (
        <div className="home-banner">
            <img
                src={imagesData[currentIndex]}
                alt={`Banner ${currentIndex + 1}`}
                className="banner-image"
            />
        </div>
    );
};

export default HomeBanner;
