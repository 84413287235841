import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import history from './../../images/history.png'

import "./Deposit.css";
// import { API } from "../../../../services/API";
import { IoArrowBackSharp } from "react-icons/io5";


const Deposit = () => {
  const [fetchUser, setFetchUser] = useState("");
  const [upArrow, setUpArrow] = useState(true);
  const [fetchReadUser, setFetchReadUser] = useState("");
  const [minimumAmount, setMinimumAmount] = useState(200);
  const [isLoading, setIsLoading] = useState(false);
  const [amountValue, setAmountValue] = useState("100");
  const [depositPrice, isDepositPrice] = useState("200");

  // const history = useHistory();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.AmountToAdd) {
      isDepositPrice(location.state.AmountToAdd.toString());
    } else {
      isDepositPrice("200");
    }
  }, [location.state]);

  // const fetchWalletDetails = useCallback(async () => {
  //   const token = JSON.parse(localStorage.getItem("storeData")).data.token;

  //   const data = JSON.stringify({ token });

  //   try {
  //     const response = await axios.post(API.wallet_info, data, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     setFetchUser(response.data.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, []);

  // const FetchDetails = useCallback(async () => {
  //   const token = JSON.parse(localStorage.getItem("storeData")).data.token;

  //   const data = JSON.stringify({ token });

  //   try {
  //     const response = await axios.post(API.readSingleuser, data, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     setFetchReadUser(response.data.data[0]);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, []);

  // useEffect(() => {
  //   fetchWalletDetails();
  //   FetchDetails();
  // }, [fetchWalletDetails, fetchReadUser]);

  // useEffect(() => {
  //   if (fetchReadUser && fetchReadUser.initial_payment_accepted === false) {
  //     setMinimumAmount(200);
  //   } else {
  //     setMinimumAmount(200);
  //   }
  // }, [fetchReadUser]);

  const handleAmountValue = (amount) => {
    setAmountValue(amount);
  };

  const handlePriceClick = (price) => {
    isDepositPrice(price);
  };

  // const handlePressPayment = async () => {
  //   try {
  //     setIsLoading(true);

  //     const token = JSON.parse(localStorage.getItem("storeData")).data.token;

  //     const data = JSON.stringify({
  //       token: token,
  //       amount: depositPrice,
  //     });

  //     console.log('data',data)

  //     const response = await axios.post(API.depositURLRequest, data, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     console.log('url',response.data)

  //     setIsLoading(false);

  //     if (response.data.success) {
  //       navigate('/PaymentGateway', { state: { responseINCompleteOrder: response.data } });
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.error(error.message);
  //   }
  // };

  return (
    <>
      <ToastContainer />
      {isLoading ? (
        <div className="loadingContainer">
          <div className="spinner"></div>
        </div>
      ) : (
        fetchUser && (
          <div className="DepositContainer">
            <div className="DepositHead">
              <button className="header" onClick={() => navigate(-1)}>
                <IoArrowBackSharp />
              </button>
              <h1 className="text1">Deposit</h1>
            </div>
            <div className="DepositContainer">
              <div className="currentBalanceContainer">
                <div className="balanceHeader">
                  <div className="innerBalanceHeader">
                    <i className="fas fa-wallet"></i>
                    <span className="balanceText">Current Balance</span>
                  </div>
                  <span className="currentBalanceAmount">
                    ₹{fetchUser.wallet.main_wallet}
                  </span>
                </div>
              </div>

              <div className="currentBalanceContainer">
                <div className="balanceHeader">
                  <div className="innerBalanceHeader">
                    <i className="fas fa-trophy"></i>
                    <span className="balanceText">Winnings</span>
                  </div>
                  <span className="currentBalanceAmount">
                    ₹{fetchUser.wallet.win_wallet}
                  </span>
                </div>
              </div>

              <p className="warningText">
                Minimum Deposit amount ₹{minimumAmount}
              </p>

              <div className="DepositAlign1">
                <div className="DepositRow">
                  <img className="GameImage1" src={history} />
                  <span className="DepositText3">Deposit amount</span>
                </div>
                <div className="DepositRow3">
                  <div
                    className={`DepositPrice ${depositPrice === "200" ? "depositPrice" : ""
                      }`}
                    onClick={() => handlePriceClick("200")}
                  >
                    {" "}
                    {"₹"}
                    <span>200</span>
                  </div>
                  <div
                    className={`DepositPrice ${depositPrice === "500" ? "depositPrice" : ""
                      }`}
                    onClick={() => handlePriceClick("500")}
                  >
                    {" "}
                    {"₹"}
                    <span>500</span>
                  </div>
                  <div
                    className={`DepositPrice ${depositPrice === "1000" ? "depositPrice" : ""
                      }`}
                    onClick={() => handlePriceClick("1000")}
                  >
                    {" "}
                    {"₹"}
                    <span>1K</span>
                  </div>
                  <div
                    className={`DepositPrice ${depositPrice === "5000" ? "depositPrice" : ""
                      }`}
                    onClick={() => handlePriceClick("5000")}
                  >
                    {" "}
                    {"₹"}
                    <span>5K</span>
                  </div>
                  <div
                    className={`DepositPrice ${depositPrice === "10000" ? "depositPrice" : ""
                      }`}
                    onClick={() => handlePriceClick("10000")}
                  >
                    {" "}
                    {"₹"}
                    <span>10K</span>
                  </div>
                  <div
                    className={`DepositPrice ${depositPrice === "20000" ? "depositPrice" : ""
                      }`}
                    onClick={() => handlePriceClick("20000")}
                  >
                    {" "}
                    {"₹"}
                    <span>20K</span>
                  </div>
                  {/* <div
                    className={`DepositPrice ${depositPrice === "50000" ? "depositPrice" : ""
                      }`}
                    onClick={() => handlePriceClick("50000")}
                  >
                    {" "}
                    {"₹"}
                    <span>50K</span>
                  </div> */}
                </div>
                <div className="DepositInputBox">
                  <span className="DepositIcon">₹</span>
                  <span>|</span>

                  <input
                    type='number'
                    className="DepositInput"
                    placeholder="Please enter Deposit amount"
                    value={depositPrice}
                    onChange={(e) => isDepositPrice(e.target.value)}
                  ></input>
                </div>

                <button

                  onClick={() => {
                    if (parseInt(depositPrice) < minimumAmount) {
                      toast.error(`Minimum Deposit amount ₹ ${minimumAmount}`);
                    } else {
                      // handlePressPayment();
                    }
                  }}
                  disabled={!depositPrice}
                  className="DepositButtonStyle">Deposit ₹ {depositPrice}</button>
              </div>

              {/* <div className="verifyContainer">
                <button
                  onClick={() => {
                    if (parseInt(amountValue) < minimumAmount) {
                      toast.error(`Minimum Deposit amount ₹ ${minimumAmount}`);
                    } else {
                      handlePressPayment();
                    }
                  }}
                  disabled={!amountValue}
                  className={`addWalletButton ${!amountValue ? "disabled" : ""}`}
                >
                  Add Wallet ₹ {amountValue}
                </button>
              </div> */}
            </div>

          </div>
        )
      )}
    </>
  );
};

export default Deposit;
