import React from 'react';
import './GameHomeCategories.css';

const HomeGameCategories = ({ gameCategories, selectedCategory, onChangeCategory }) => {
    return (
        <div className="home-menu">
            {gameCategories.map((category, index) => (
                <div className="category" onClick={()=>{ onChangeCategory(index) }}>
                    <div className={`menu-item ${ selectedCategory === index ? 'checked' :''}`} key={index}>
                        <img src={category.image} alt={category.name} className={category.name.toLowerCase()} />
                    </div>
                    <span>{category.name}</span>
                </div>
            ))}
        </div>
    );
};

export default HomeGameCategories;
