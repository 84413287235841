import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiGet } from '../../services/apiService';
import API_ENDPOINTS from "../../config/api";
import './games.css';

const GameSlots = () => {
    const { gameid } = useParams();
    const [gameData, setGameData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchGameData = async () => {
            try {
                const base_url = API_ENDPOINTS.GAMES.LAUNCH_REQUEST_SLOTS;
                const response = await apiGet(`${base_url}/${gameid}`);
                if (response.status === 'success') {
                    setGameData(response.data);
                } else {
                    setError(response.message);
                }
            } catch (err) {
                setError('An error occurred while fetching the game data.');
            }
        };
        fetchGameData();
    }, [gameid]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!gameData) {
        return <div>Loading game data...</div>;
    }

    const { game_url, game_frame } = gameData;

    return (
        <div className="full-height">
            {game_url ? (
                <iframe
                    width="100%"
                    className={`fullFitCanvas`}
                    height="100%"
                    src={game_url}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            ) : game_frame ? (
                <div dangerouslySetInnerHTML={{ __html: game_frame }} />
            ) : (
                <div>No game data available.</div>
            )}
        </div>
    );
};

export default GameSlots;
