import React from 'react';
import './SplashScreen.css';
import splash from './../../images/logo_2.png'


function SplashScreen() {
    return (
        <div className="splash-screen-content">
            <img src={splash} alt="Splash Image" className="SplashImage" />
        </div>
    );
}

export default SplashScreen;
