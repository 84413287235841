import React, {useContext, useEffect, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { apiPost } from '../../services/apiService';
import API_ENDPOINTS from '../../config/api';
import { MdPhoneAndroid, MdEmail, MdOutlineSupportAgent } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import {IoIosArrowBack, IoMdEye, IoMdEyeOff} from "react-icons/io";
import { IoChevronBackCircleOutline} from "react-icons/io5";
import { BsPhoneFill } from "react-icons/bs";
import splash from "../../images/logo_2.png";
import LanguagePopup from "../../components/languagePopup/languagePopup";
import {UserContext} from "../../store/UserContext";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {saveLanguage, saveUser} from "../../services/authService";

function Login() {
    const { state, dispatch } = useContext(UserContext);
    const [formData, setFormData] = useState({ email: '', phone: '', password: '' });
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [rememberPassword, setRememberPassword] = useState(false);
    const [activeTab, setActiveTab] = useState("phone");
    const navigate = useNavigate();
    const [langId, setLangId] = useState(0);
    const [showLanguageSelection, setShowLanguageSelection] = useState(false);
    const popupRef = useRef(null);

    // Destructure formData for cleaner access
    const { email, phone, password } = formData;

    const languages = [
        { class: 'lang-en', title: 'EN'},
        { class: 'lang-hi', title: 'HI'}
    ];

    //Check User Auth State
    useEffect(() => {
        if (state.isAuthenticated) {
            console.log("User Logged");
            navigate('/');
        }
    }, [state.isAuthenticated, navigate]);

    const handleLangChange = (langIndex) => {
        setLangId(langIndex);
        saveLanguage(langIndex);
    };

    // Handle form input changes
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleEyeToggle = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const loginData = activeTab === "phone" ? { type: activeTab, username: phone, password } : { type: activeTab, username: email, password };
            const response = await apiPost(API_ENDPOINTS.AUTH.LOGIN, loginData);
            toast.success(response.message);
            if(response.data.token){
                saveUser(response.data.user, response.data.token);
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        token: response.data.token,
                        user: response.data.user
                    }
                });
                navigate('/');
            }else{
                toast.error('An error occurred during login');
            }


        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.message || 'An error occurred during login');
            } else {
                toast.error('Network error, please check your connection');
            }
        }
    };

    return (
        <form className={"mainContainer"}>

            <ToastContainer />

            <div className={"top-header"}>
                <span onClick={() => navigate("/")}><IoIosArrowBack className={"backButtonStyle"}/></span>
                <span className="logo-header">
                        <img src={splash} alt="Arena Betz"/>
                    </span>
                <span className="lang-header" onClick={() => {
                    setShowLanguageSelection(true)
                }}>
                        <span className={`${languages[langId].class}`}></span>
                        <span className='lang-title'>{languages[langId].title}</span>
                    </span>

            </div>
            <div className={"headerContainer"}>
                <div className={"titleContainer"}>
                    <div className="title">Log in</div>
                    <div className={"description"}>
                        Please log in with your phone number or email.<br/>If you forget your password, please contact
                        customer service
                    </div>
                </div>
            </div>

            {/* Tabs for Phone and Email Login */}
            <div className="tabRowStyle">
                <div
                    className={`tabStyle ${activeTab === "phone" ? "activeTab" : ""}`}
                    onClick={() => handleTabChange("phone")}
                >
                    <BsPhoneFill className={"headIconStyle"}/>
                    <div className={"titleContainer1"}>Log in with phone</div>
                </div>
                <div
                    className={`tabStyle ${activeTab === "email" ? "activeTab" : ""}`}
                    onClick={() => handleTabChange("email")}
                >
                    <MdEmail className={"headIconStyle"}/>
                    <div className={"titleContainer1"}>Log in with email</div>
                </div>
            </div>

            {/* Input Fields */}
            <div className={"inputContainer"}>
                <div className={"row"}>
                    {activeTab === "phone" ? (
                        <MdPhoneAndroid className={"iconStyle"}/>
                    ) : (
                        <MdEmail className={"iconStyle"}/>
                    )}
                    <div className={"titleContainer2"}>
                        {activeTab === "phone" ? "Phone number" : "Email"}
                    </div>
                </div>
                <div className="rowOfPH">
                    {activeTab === "phone" && <div className="Phcode">+91</div>}
                    <input
                        name={activeTab === "phone" ? "phone" : "email"}
                        value={activeTab === "phone" ? phone : email}
                        placeholder={activeTab === "phone"
                            ? "Please enter your phone number"
                            : "Please enter your email"}
                        onChange={handleChange}
                        className={"inputBox"}
                    />
                </div>
            </div>

            <div className={"inputContainer"}>
                <div className={"row"}>
                    <RiLockPasswordFill className={"iconStyle"}/>
                    <div className={"titleContainer2"}>Password</div>
                </div>
                <div className="inputBox1">
                    <input
                        name="password"
                        value={password}
                        type={passwordVisible ? "text" : "password"}
                        placeholder="Password"
                        onChange={handleChange}
                        className="placeHolder"
                    />
                    <div className="eyeIconContainer" onClick={handleEyeToggle}>
                        {passwordVisible ? (
                            <IoMdEye className={"eyeIconStyle"}/>
                        ) : (
                            <IoMdEyeOff className={"eyeIconStyle"}/>
                        )}
                    </div>
                </div>

                {/* Remember Password */}
                <div className="radioRow" onClick={() => setRememberPassword(!rememberPassword)}>
                    <input
                        type="radio"
                        id="customRadio"
                        className="radio"
                        value="option1"
                        checked={rememberPassword}
                        onChange={() => setRememberPassword(!rememberPassword)}
                    />
                    <label htmlFor="customRadio" className={`radioLabelRegister ${rememberPassword ? 'checked' : ''}`}>
                        Remember password
                    </label>
                </div>

            </div>

            <br/>
            <div className={"inputContainer1"}>
                <button className={"primaryButton"} onClick={handleLogin}>
                    Log in
                </button>
                <button className={"primaryButtonOutline"} onClick={() => navigate('/register')}>
                    Register
                </button>
                <div className="CustomerSupportStyle">
                    <MdOutlineSupportAgent className={"supportIconStyle"}/>
                    <div className={"titleContainer3"}>Customer Service</div>
                </div>
            </div>

            {/*Language Selector*/}
            {
                showLanguageSelection && (
                    <div ref={popupRef}>
                        <LanguagePopup langId={langId} languages={languages}
                                       closePopup={() => setShowLanguageSelection(false)}
                                       onLangChange={handleLangChange}></LanguagePopup>
                    </div>
                )
            }

        </form>
    );
}

export default Login;
