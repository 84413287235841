
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Promotion.css";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { FaCopy } from "react-icons/fa";
import BottomNavigator from "../../components/bottomNavigator/BottomNavigator";

const Promotion = (props) => {
  let navigate = useNavigate();
  return (
    <div className={"promoContainer"}>
      <div className="promoheader">Promotion</div>

      <div className="pmiddle">
        <div className="pmiddle1">
          <div className="pmiddle1value">0</div>
          <div className="pmiddle1text1">Yesterday's total commission</div>
          <div className="pmiddle1text2">
            Upgrade the level to increase commission income
          </div>
        </div>
        <div className="pmiddle3">

          <div className="pmiddle2">
            <div className="promotionRow">
              <div className="pmiddle2text">
                {/* <img
                src={
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALvSURBVHgB7Zg9bxNBEIbfuWAsoVhEooIGU9HwzQ+I01ARBRGJGkQBCIHoKHFKOiKBEAWyqJAiIVkKPXRUxJagScWlgQZCAhQEsJd318b44+7sOWPOie6RTr7zzu3N7OzuzA6QkpKyrREMgXmIAns4x2uOXeWb/1ZhYK8FuQYfIyaWAaaEKfzAHb5+q4/kPeymIZewEdh6H/uQwVl4WMVerMgF9qrEgxKn/E950V95C2Uo694J7AxZd9VwjCbOmiWaq0RtgBt5gxMDy1tZ561e5Abe0wNPMYGvqNMbmzgFJaopxDmfhyfvEIe6meGaeBnY72Pk8AvnnTcEy3IFHzAgOg94KCIudrGHNV2mB2p46x4MB0mlkkoJOY64iMxFttdao56HAp0BmrnfSz6q0a2HBjko0C/iMUNrwBriU41qdDGhwUcoUE4hU0ZcjEQawAhwpCGHdSjQroEhDKgvhDYtYZJx4LB7+ILXUKAywO3jIovQYmQxLC9yym9itvn4Rm5zS1WgX8SZepF7enVgeSub5TsBuHn/GfPcQnPU5BMj9gqUqA1wiVnGzAzkCY68lQ1L5lq5EEce3/FcbmILSoZNp5laeEXe2fjwJ8itUfEy53w5LHVISUlJ2Tn03UbbKg/TfOTZVqYav1qMzz3f5439fTLIFmvuItcvMoca4PZ4QYkBq4DR4DePmT7Cv3+Gt/a0tizX8S1ILjASmwc8uHhSGaHyFnu+rrhvBVGj4hNu9HPYhfm2dLuDHg80D+4VxJomsdigJ04GecKVWdZxmrdHqekWD/7Puj3R6wHP1nz+m/Jw35qQUlCDLXTJVbyiGasuZ8pwPXbRYYBbsMpD9T/BoND8djCTNMJ6oI4D5hH2tzd1esDDRSRFVNnFlhxDyi6dBhgcRFKITEe2/y27RHhA5BCSI3rd7WnFg2z7392LODkP9DMg1zzsSGcBeJzqQpEGtErvJtoD247UgKTZcQb4SA6/r0QjuVMVvlJSUsac37bv1wTQ3N6aAAAAAElFTkSuQmCC"
                }
                alt="Image"
                style={{ width: "30px", height: "30px", paddingTop: "5px" }}
              /> */}
                <div className="pmiddle2innertext">Direct subordinates</div>
              </div>

              <div className="pmiddle2text">
                {/* <img
                src={
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALvSURBVHgB7Zg9bxNBEIbfuWAsoVhEooIGU9HwzQ+I01ARBRGJGkQBCIHoKHFKOiKBEAWyqJAiIVkKPXRUxJagScWlgQZCAhQEsJd318b44+7sOWPOie6RTr7zzu3N7OzuzA6QkpKyrREMgXmIAns4x2uOXeWb/1ZhYK8FuQYfIyaWAaaEKfzAHb5+q4/kPeymIZewEdh6H/uQwVl4WMVerMgF9qrEgxKn/E950V95C2Uo694J7AxZd9VwjCbOmiWaq0RtgBt5gxMDy1tZ561e5Abe0wNPMYGvqNMbmzgFJaopxDmfhyfvEIe6meGaeBnY72Pk8AvnnTcEy3IFHzAgOg94KCIudrGHNV2mB2p46x4MB0mlkkoJOY64iMxFttdao56HAp0BmrnfSz6q0a2HBjko0C/iMUNrwBriU41qdDGhwUcoUE4hU0ZcjEQawAhwpCGHdSjQroEhDKgvhDYtYZJx4LB7+ILXUKAywO3jIovQYmQxLC9yym9itvn4Rm5zS1WgX8SZepF7enVgeSub5TsBuHn/GfPcQnPU5BMj9gqUqA1wiVnGzAzkCY68lQ1L5lq5EEce3/FcbmILSoZNp5laeEXe2fjwJ8itUfEy53w5LHVISUlJ2Tn03UbbKg/TfOTZVqYav1qMzz3f5439fTLIFmvuItcvMoca4PZ4QYkBq4DR4DePmT7Cv3+Gt/a0tizX8S1ILjASmwc8uHhSGaHyFnu+rrhvBVGj4hNu9HPYhfm2dLuDHg80D+4VxJomsdigJ04GecKVWdZxmrdHqekWD/7Puj3R6wHP1nz+m/Jw35qQUlCDLXTJVbyiGasuZ8pwPXbRYYBbsMpD9T/BoND8djCTNMJ6oI4D5hH2tzd1esDDRSRFVNnFlhxDyi6dBhgcRFKITEe2/y27RHhA5BCSI3rd7WnFg2z7392LODkP9DMg1zzsSGcBeJzqQpEGtErvJtoD247UgKTZcQb4SA6/r0QjuVMVvlJSUsac37bv1wTQ3N6aAAAAAElFTkSuQmCC"
                }
                alt="Image"
                style={{ width: "30px", height: "30px", paddingTop: "5px" }}
              /> */}
                <div className="pmiddle2innertext">Team subordinates</div>
              </div>
            </div>

            {/* <div className="pmiddle2text2">Team subordinates</div>  */}
          </div>

          <div className="promotionDirectAlign">
            <div>
              <div className="promotionColumnAlign">
                <div className="promotionText1">0</div>
                <div className="promotionText2">number of register</div>
              </div>
              <div className="promotionColumnAlign">
                <div className="promotionText3">0</div>
                <div className="promotionText2">Deposit number</div>
              </div>
              <div className="promotionColumnAlign">
                <div className="promotionText4">0</div>
                <div className="promotionText2">Deposit amount</div>
              </div>
              <div className="promotionColumnAlign">
                <div className="promotionText5">0</div>
                <div className="promotionText2">
                  Number of people making first deposit
                </div>
              </div>
            </div>

            <div className="divider">{ }</div>

            <div>
              <div className="promotionColumnAlign">
                <div className="promotionText1">0</div>
                <div className="promotionText2">number of register</div>
              </div>
              <div className="promotionColumnAlign">
                <div className="promotionText3">0</div>
                <div className="promotionText2">Deposit number</div>
              </div>
              <div className="promotionColumnAlign">
                <div className="promotionText4">0</div>
                <div className="promotionText2">Deposit amount</div>
              </div>
              <div className="promotionColumnAlign">
                <div className="promotionText5">0</div>
                <div className="promotionText2">
                  Number of people making first deposit
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button className="promotionLinkButton">INVITATION LINK</button>
      <div className="promotionButton">
        <div className="promotionDiv1">
          <img
            style={{ height: 42, width: 42 }}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALBSURBVHgB7ZmxbxMxFMa/d6RFDTCFhYoBFhgpYiEsoCLBAhJMXckUVbCwsvUPQGIAwRhWJiSYUTrBgkQlFjq1YiUoAwpCED++uyARnX0X5+Im1zY/6aLIfnf29+z37DsDc+YcbsTXUF9hER2cg8EyjqDGohMIifLpwC9eO2xjR+7jh89tIwWw48f56Gu0XMY0ifAFv/FxlJBcAfqUHl9And45ilkgHBGDTVnnqGSaZKDPcYa1N1AG/qAtD7Dtqopchcm0iXAVZYGzQJ+xTw6cAtDFpZlNGxdxXxYYhw4sAYn3Dc6jbDD7JZkwhT0CHc79shKn8RQVy8jgLPP8JNQY/DVnzSDXd1CUKEnln4eLKg6jRRSnDpF7mbVJztPHFLKNIqjtmMjRiNt7PgiWPGwmeb7l3ArC8oHXSV5uIYrv/N1CURyZMawARS/JY/k2QYmwzwk3AooVuuOCp/UW7T8hAOEERNxy+bNCxUEEhJxC4+T3nwhEwCmkjwDPFCllFDDAbxQCZqIDmIUEp5EEWQ6DPU28HSi+rxnGoMr912X+qyK/3ffpIuuNTF/IN//lXt/woW8xCYJV/tzGqM4P6ElTjw0XuEZgjL0KGxbtUcQ7FEFw61/nfbFEBoiBWISX99LUxuy8kxBBHHd+FeMzcedjAmUhuQ6MtU3mS4/UEYBQabQ6mM/eBPF+TMCFTK4woL8yoPP3+8K3NoTxfkzglVjW2ME1TJF9vxK7BOyivHTTBbYADbNP3yOs+LIFGLRRVvr6Ol1kC1hCC2VFMFqANDjPpISjoNJynRO4s1BfG3AEzAzpQs2Gq8IpIFFq9CFKg2xkndJkrgO8ocVxu4vZjkQXJmpI0zzJMshdyKTJoDF6kf9eYvpsxm3Ler+VZ+R/zBqfmSnu8PvPTd51ikW+H7F82U0+dhltx9km72Bvzpw5//kLlqijMJTcNZkAAAAASUVORK5CYII="
          />
          <span className="promotionDivText">352187261734</span>
        </div>
        <div className="promotionDiv2">
          {/* <span>352187261734</span> */}
          <FaCopy style={{ fontSize: 20 }} />
        </div>
      </div>

      <div className="promotionButton" onClick={() => navigate('/SubordinateData')}>
        <div className="promotionDiv1">
          <img
            style={{ height: 42, width: 42 }}
            src="https://9987up.club/assets/png/team_port-b79e3d75.png"
          />
          <span className="promotionDivText">Subordinate data</span>
        </div>
        <div className="promotionDiv2">
          {/* <span>352187261734</span> */}
          <MdOutlineArrowForwardIos />
        </div>
      </div>
      <div className="promotionButton">
        <div className="promotionDiv1">
          <img
            style={{ height: 42, width: 42 }}
            src="https://9987up.club/assets/png/commission-4abb55b3.png"
          />
          <span className="promotionDivText">Commission detail</span>
        </div>
        <div className="promotionDiv2">
          {/* <span>352187261734</span> */}
          <MdOutlineArrowForwardIos />
        </div>
      </div>

      <div className="promotionButton">
        <div className="promotionDiv1">
          <img
            style={{ height: 42, width: 42 }}
            src="https://9987up.club/assets/png/subordinate-d83ad4d6.png"
          />
          <span className="promotionDivText">New subordinates</span>
        </div>
        <div className="promotionDiv2">
          <span>352187261734</span>
          <MdOutlineArrowForwardIos />
        </div>
      </div>

      <div className="promotionButton">
        <div className="promotionDiv1">
          <img
            style={{ height: 42, width: 42 }}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMGSURBVHgB7Zq9ixNBGMafSc4cykWQS2MQzkobRfFsTOUfYFoLG8FG8aOx8W+wuNbzA8H6BJvYrlh5jRFEG69SkDsET+Wih55JxmfmkngKmdnZy34yP9hskp3dnWffd9955wPweDyeHCEQEXkbVezHHPqoo4xZXqkCienBVW/y4yh2xwqkXBhc7xfv851bByWscv9eXOPvCEzBEXkPB3nDeVairv8oDw8gPtSDFNzUgwUOc9+Qi1r4c1fhJZfC8g6FSjRHYtNEaM+6QAOccjkttGAttsQta0icdhEdSrC8jyOZFDtEiV7EsTBFrYLlEmboPtkVO4QGYV0r9mI2vvFd6TEiZx0V2L7arWwX3MNx5IUQwdQoWLuI0E1BPmBOYHNrs4U/oYa8scE8wYBZ8DQDVt74Ocj2xmAW3M1BsPqfkrnOTplWEfCCE0FgU28p4NxbmgAfdbdPYC+VX+XvQ0iQpC28wtZ9gVnRJtvMdf1d/5ccSQpe1paVO1xZfd/u5C8jIZISHFDYo7FH1TGJp0iA+N9hLUS2QhRsQQoV0M4hRuIRLPCaQmsUEDDBDcKfOBJ9BkK+iGPYKB7BfbowZBBtoEuJRiuuMTKfeBQdL7joeMFFJ43Ow07UnNRF7l2Gkt5xe/xPiupA2oKbESbdGmyr2fGIloqm69K6ixiJfYhIuhYWWKJrqsq7DAWv87wgaiaWruC/fWI3dpF2+map6HjBRcdnWhNhe5imgXiIMdOaQodNhxsCJ/nRRHyYMy21tMmA2aV72II7ScwojM+09pgFmy08i1V8gRtSD6w/o6VPIB7MmdYBlcyMx7oST95VCb55kjlDfBZX8MRUwB6l+7RyXujira2IXXANb/Rax+zTwQ+s2QpZBYvzOnC9Qtbpoi1umQOWIlTiIS7TymW0kVUqeCmuh5uFdFo+LB9gnk1VtlblKbGXwnug83pp+RBV/MZZpB+511iPtrjhFlSjLxBXwrcwx5eizqtU2S7OjBaITxoVNEu8mxowUK3GBj6EeV89Ho8n9/wBmZ7QsYaLgg0AAAAASUVORK5CYII="
          />
          <span className="promotionDivText">Invitation rules</span>
        </div>
        <div className="promotionDiv2">
          <MdOutlineArrowForwardIos />
        </div>
      </div>

      <div className="promotionButton">
        <div className="promotionDiv1">
          <img
            style={{ height: 42, width: 42 }}
            src="https://9987up.club/assets/png/server-a789bbfb.png"
          />
          <span className="promotionDivText">Agent line customer service</span>
        </div>
        <div className="promotionDiv2">
          <MdOutlineArrowForwardIos />
        </div>
      </div>

      <div className="promotionButton">
        <div className="promotionDiv1">
          <img
            style={{ height: 42, width: 42 }}
            src="https://9987up.club/assets/png/rebateRatio-1e7d4e17.png"
          />
          <span className="promotionDivText">Rebate ratio</span>
        </div>
        <div className="promotionDiv2">
          <MdOutlineArrowForwardIos />
        </div>
      </div>

      <div className="promoBottom">
        <div className="promotionBottom">
          <img
            src="https://9987up.club/assets/png/money-5fbba619.png"
            className="promotionIcon"
          />
          <span className="promotionHeaderText">promotion data</span>
        </div>
        <div className="promotionBottomAlign">
          <div className="promotionCenter">
            <div className="promotionBottomColumnAlign">
              <div className="promotionText1">0</div>
              <div className="promotionText2">This Week</div>
            </div>
            <div className="promotionBottomColumnAlign">
              <div className="promotionText3">1</div>
              <div className="promotionText2">direct subordinate</div>
            </div>
          </div>
          <div className="divider1">{ }</div>

          <div className="promotionCenter">
            <div className="promotionBottomColumnAlign">
              <div className="promotionText1">0</div>
              <div className="promotionText2">Total commission</div>
            </div>
            <div className="promotionBottomColumnAlign">
              <div className="promotionText3">0</div>
              <div className="promotionText2">Total number of subordinate in the team</div>
            </div>
          </div>
        </div>
      </div>



      <BottomNavigator></BottomNavigator>

    </div>

  );
};

export default Promotion;